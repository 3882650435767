// ViewContactList.js

import React, { useContext, useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { ContactListContext } from '../contexts/ContactListContext';
import { getClipsPostsByUserId } from '../utils/dbUtilities';
import { Navigate } from 'react-router-dom';
import { timeAgo } from '../utils/timeFormatter';
import './ViewContactList.css';

const ViewContactList = () => {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { currentUser } = useAuth();
  const { contactList, fetchContactList } = useContext(ContactListContext);

  useEffect(() => {
    const fetchPostsAndContacts = async () => {
      if (currentUser) {
        try {
          setIsLoading(true);
          const userPosts = await getClipsPostsByUserId(currentUser.uid);
          setPosts(userPosts);
          await fetchContactList(currentUser.uid);
        } catch (error) {
          console.error("Error fetching posts and contacts:", error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchPostsAndContacts();
  }, [currentUser, fetchContactList]);

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (posts.length === 0) {
    return <p>You don't have any posts yet.</p>;
  }

  return (
    <div className="view-contact-list-container">
      <div className="header-box">
        <h1>Email Sign Up Lists</h1>
        <h3>Users who Signed Up for Email Updates About Your Post(s)</h3>
      </div>
      {posts.map(post => (
        <div key={post.id} className="post-contact-list">
          <h2>{post.title || 'Untitled Post'}</h2>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email Address</th>
                <th>Timestamp</th>
              </tr>
            </thead>
            <tbody>
              {contactList[post.id] && contactList[post.id].map((contact, index) => (
                <tr key={index}>
                  <td>{contact.name}</td>
                  <td>{contact.email}</td>
                  <td>{timeAgo(contact.timestamp)}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {(!contactList[post.id] || contactList[post.id].length === 0) && (
            <p>No Email List Sign Ups</p>
          )}
        </div>
      ))}
    </div>
  );
};

export default ViewContactList;
