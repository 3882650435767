// CategoryPosts.js

import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ClipsPostsContext } from '../contexts/ClipsPostsContext';
import ClipsPost from './ClipsPost';
import { Typography } from '@mui/material';
import './UsersPosts.css';

const CategoryPosts = () => {
    const { category } = useParams();
    const { posts } = useContext(ClipsPostsContext);
    const [categoryPosts, setCategoryPosts] = useState([]);

    useEffect(() => {
        if (category && posts.length > 0) {
            const filteredPosts = posts.filter(post => post.categories && post.categories.includes(category));
            setCategoryPosts(filteredPosts);
        }
    }, [category, posts]);

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
    }, []);

    return (
        <div className="users-posts-container">
            <h1>Posts in Category: {category}</h1>
            {categoryPosts.length > 0 ? (
                categoryPosts.map(post => (
                    <ClipsPost 
                        key={post.id}
                        id={post.id}
                        title={post.title}
                        author={post.author}
                        description={post.description}
                        location={post.location}
                        imageUrl={post.imageUrl}
                        timestamp={post.timestamp}
                        city={post.city}
                        state={post.state}
                        country={post.country}
                        categories={post.categories}
                        link={post.link}
                        instagram={post.instagram}
                        twitter={post.twitter}
                        tiktok={post.tiktok}
                        youtube={post.youtube}
                    />
                ))
            ) : (
                <Typography variant="body1" color="text.secondary">
                    No posts found in this category.
                </Typography>
            )}
        </div>
    );
};

export default CategoryPosts;
