// ClipsEditPostForm.js

import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { collection, query, where, orderBy, limit, getDocs } from 'firebase/firestore';
import { db } from '../utils/firebaseConfig';
import { useAuth } from '../contexts/AuthContext';
import { ClipsPostsContext } from '../contexts/ClipsPostsContext';
import { IoMdCloudUpload, IoMdImages } from 'react-icons/io';
import { FaInstagram, FaTwitter, FaTiktok, FaYoutube, FaFacebook } from 'react-icons/fa';
import { useDropzone } from 'react-dropzone';
import CloseButton from './CloseButton';
import { uploadImageToStorage, updatePostSummary } from '../utils/dbUtilities';
import heic2any from 'heic2any';
import './PostForm.css';
import Cropper from 'react-easy-crop';
import 'react-easy-crop/react-easy-crop.css';

const MAX_FILE_SIZE = 5 * 1024 * 1024;
const invalidFileTypes = ['video/mp4', 'audio/wav', 'image/gif', 'video/x-ms-wmv'];

const checkFileValidity = (file, setError, setProcessing, setProgress) => {
  if (invalidFileTypes.includes(file.type)) {
    const errorMsg = 'Invalid file type. MP4, WAV, GIF, and WMV files are not allowed.';
    setError(errorMsg);
    setProcessing(false);
    setProgress(0);
    return false;
  }

  if (file.size > MAX_FILE_SIZE) {
    const errorMsg = 'File size exceeds 5 MB limit.';
    setError(errorMsg);
    setProcessing(false);
    setProgress(0);
    return false;
  }

  setError('');
  return true;
};

const compressImage = (file, setProgress) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, img.width, img.height);
      canvas.toBlob((blob) => {
        setProgress(70);
        resolve(blob);
      }, 'image/jpeg', 0.5);
    };
    img.onerror = reject;
    img.src = URL.createObjectURL(file);
  });
};

const convertImageToJPG = async (file, setProgress) => {
  if (file.type === 'image/heic') {
    try {
      const convertedBlob = await heic2any({
        blob: file,
        toType: 'image/jpeg',
        quality: 0.92,
      });
      setProgress(50);
      return convertedBlob;
    } catch (error) {
      console.error('Error converting image:', error);
      throw error;
    }
  } else {
    return compressImage(file, setProgress);
  }
};

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.src = url;
  });

const getCroppedImg = async (imageSrc, pixelCrop, circular = false) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  canvas.width = safeArea;
  canvas.height = safeArea;

  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.translate(-safeArea / 2, -safeArea / 2);

  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );

  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.putImageData(
    data,
    0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
    0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
  );

  if (circular) {
    ctx.globalCompositeOperation = 'destination-in';
    ctx.beginPath();
    ctx.arc(canvas.width / 2, canvas.height / 2, canvas.width / 2, 0, 2 * Math.PI, true);
    ctx.fill();
  }

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(blob);
    }, 'image/jpeg');
  });
};

function MyDropzone({ onImageUpload, setIsImageProcessing, setProgress, setUploadError, initialImage }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [originalImage, setOriginalImage] = useState(null);
  const [croppedImagePreview, setCroppedImagePreview] = useState(initialImage);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  useEffect(() => {
    if (initialImage) {
      setCroppedImagePreview(initialImage);
    }
  }, [initialImage]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleSave = async (event) => {
    event.preventDefault();
    try {
      const croppedImage = await getCroppedImg(selectedImage, croppedAreaPixels);
      const croppedImageUrl = URL.createObjectURL(croppedImage);
      setCroppedImagePreview(croppedImageUrl);
      setSelectedImage(null);
      onImageUpload(croppedImage);
    } catch (error) {
      console.error('Error cropping image:', error);
    }
  };

  const handleCancel = () => {
    setSelectedImage(null);
    setCroppedImagePreview(initialImage);
    setOriginalImage(null);
  };

  const handleEdit = (event) => {
    event.preventDefault();
    setSelectedImage(croppedImagePreview);
    setCroppedImagePreview(null);
  };

  const handleRemove = () => {
    setSelectedImage(null);
    setCroppedImagePreview(null);
    setOriginalImage(null);
    onImageUpload(null);
  };

  const onDrop = async (acceptedFiles) => {
    setIsImageProcessing(true);
    setProgress(5);
    const file = acceptedFiles[0];

    if (!checkFileValidity(file, setUploadError, setIsImageProcessing, setProgress)) {
      return;
    }

    const imageBlob = file.type === 'image/heic' ? await convertImageToJPG(file, setProgress) : await compressImage(file, setProgress);
    const imageUrl = URL.createObjectURL(imageBlob);
    setOriginalImage(imageUrl);
    setSelectedImage(imageUrl);
    setCroppedImagePreview(null);
    setProgress(80);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop
  });

  return (
    <div className="image-upload-section">
      {!selectedImage && !croppedImagePreview && (
        <div {...getRootProps()} className="drag-drop-zone">
          <input {...getInputProps()} />
          <IoMdCloudUpload size={50} className="upload-icon" />
          <p>Drag & Drop or Click Here to Upload Image (5 MB Size Limit)</p>
          <IoMdImages size={50} className="images-icon" />
        </div>
      )}

      {croppedImagePreview && (
        <div className="image-preview-container">
          <img src={croppedImagePreview} alt="Cropped" className="uploaded-image-preview" />
          <button onClick={handleRemove} className="remove-image-icon">X</button>
          <button onClick={handleEdit} className="edit-button">Edit</button>
        </div>
      )}

      {selectedImage && !croppedImagePreview && (
        <div className="cropper-container">
          <Cropper
            image={selectedImage}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            cropShape="rect"
            showGrid={false}
            style={{
              containerStyle: {
                position: 'relative',
                width: '100%',
                height: '400px',
              },
              cropAreaStyle: {
                border: '2px solid white',
              },
              mediaStyle: {
                transform: 'translateZ(0)',
              }
            }}
          />
          <div className="cropper-toolbar">
            <button type="button" onClick={handleSave} className="save-button">Save</button>
            <button type="button" onClick={handleCancel} className="cancel-button">Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
}

function ProfileImageDropzone({ onImageUpload, setIsImageProcessing, setProgress, setUploadError, initialImage }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [originalImage, setOriginalImage] = useState(null);
  const [croppedImagePreview, setCroppedImagePreview] = useState(initialImage);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  useEffect(() => {
    if (initialImage) {
      setCroppedImagePreview(initialImage);
    }
  }, [initialImage]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleSave = async (event) => {
    event.preventDefault();
    try {
      const croppedImage = await getCroppedImg(selectedImage, croppedAreaPixels, true);
      const croppedImageUrl = URL.createObjectURL(croppedImage);
      setCroppedImagePreview(croppedImageUrl);
      setSelectedImage(null);
      onImageUpload(croppedImage);
    } catch (error) {
      console.error('Error cropping image:', error);
    }
  };

  const handleCancel = () => {
    setSelectedImage(null);
    setCroppedImagePreview(initialImage);
    setOriginalImage(null);
  };

  const handleEdit = (event) => {
    event.preventDefault();
    setSelectedImage(croppedImagePreview);
    setCroppedImagePreview(null);
  };

  const handleRemove = () => {
    setSelectedImage(null);
    setCroppedImagePreview(null);
    setOriginalImage(null);
    onImageUpload(null);
  };

  const onDrop = async (acceptedFiles) => {
    setIsImageProcessing(true);
    setProgress(5);
    const file = acceptedFiles[0];

    if (!checkFileValidity(file, setUploadError, setIsImageProcessing, setProgress)) {
      return;
    }

    const imageBlob = file.type === 'image/heic' ? await convertImageToJPG(file, setProgress) : await compressImage(file, setProgress);
    const imageUrl = URL.createObjectURL(imageBlob);
    setOriginalImage(imageUrl);
    setSelectedImage(imageUrl);
    setCroppedImagePreview(null);
    setProgress(80);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop
  });

  return (
    <div className="image-upload-section profile-image">
      {!selectedImage && !croppedImagePreview && (
        <div {...getRootProps()} className="drag-drop-zone circular">
          <input {...getInputProps()} />
          <IoMdCloudUpload size={50} className="upload-icon" />
          <p>Upload Profile Image (5 MB Size Limit)</p>
          <IoMdImages size={50} className="images-icon" />
        </div>
      )}

      {croppedImagePreview && (
        <div className="image-preview-container circular">
          <img src={croppedImagePreview} alt="Cropped" className="uploaded-image-preview" />
          <button onClick={handleRemove} className="remove-image-icon">X</button>
          <button onClick={handleEdit} className="edit-button">Edit</button>
        </div>
      )}

      {selectedImage && !croppedImagePreview && (
        <div className="cropper-wrapper circular">
          <div className="cropper-container circular">
            <Cropper
              image={selectedImage}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              cropShape="round"
              showGrid={false}
              style={{
                containerStyle: {
                  width: '100%',
                  height: '300px',
                  position: 'relative',
                },
                cropAreaStyle: {
                  border: '2px solid white',
                  borderRadius: '50%',
                },
                mediaStyle: {
                  transform: 'translateZ(0)',
                }
              }}
            />
          </div>
          <div className="cropper-toolbar">
            <button type="button" onClick={handleSave} className="save-button">Save</button>
            <button type="button" onClick={handleCancel} className="cancel-button">Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
}

const ClipsEditPostForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useAuth();
  const { updateSinglePost, updatePostInDB } = useContext(ClipsPostsContext);
  const [post, setPost] = useState(null);
  const [uploadedImageBlob, setUploadedImageBlob] = useState(null);
  const [isImageProcessing, setIsImageProcessing] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploadError, setUploadError] = useState('');
  const [nickname, setNickname] = useState('');
  const [description, setDescription] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [userLocation, setUserLocation] = useState('');
  const [link, setLink] = useState('');
  const [instagram, setInstagram] = useState('');
  const [twitter, setTwitter] = useState('');
  const [tiktok, setTiktok] = useState('');
  const [youtube, setYoutube] = useState('');
  const [facebook, setFacebook] = useState('');
  const [wordCount, setWordCount] = useState(0);
  const [fieldErrors, setFieldErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoryError, setCategoryError] = useState(false);

  // Profile-related state
  const [shareProfile, setShareProfile] = useState(false);
  const [bio, setBio] = useState('');
  const [bioWordCount, setBioWordCount] = useState(0);
  const [profileImageBlob, setProfileImageBlob] = useState(null);
  const [profileImagePreview, setProfileImagePreview] = useState(null);
  const [profileImageError, setProfileImageError] = useState('');
  const [profileImageProgress, setProfileImageProgress] = useState(0);
  const [profileImageProcessing, setProfileImageProcessing] = useState(false);
  const [userName, setUserName] = useState('');
  const [postImagePreview, setPostImagePreview] = useState(null);

  // New state for email
  const [email, setEmail] = useState('');

  // Create a sorted array of categories
  const sortedCategories = [
    'Art', 'Aspiring Influencer', 'Beta Testing & Feedback', 'Business & Startups',
    'Charity', 'Collectors', 'Comedy', 'Entertainment', 'Fashion & Clothing',
    'Food', 'Health', 'Home & Living', 'Media', 'Money & Finance',
    'Music', 'New Project', 'News & Current Events', 'Podcast', 'Productivity',
    'Social Media', 'Sports', 'Tech', 'Toys', 'TV & Film', 'Vintage'
  ].sort();

  // Add "Other" to the end of the sorted array
  sortedCategories.push('Other');

  // Scroll to the top when the component is loaded
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const updateWordCount = (text) => {
    const words = text.split(/\s+/).filter(word => word.length > 0);
    setWordCount(words.length);
  };

  const updateBioWordCount = (text) => {
    const words = text.split(/\s+/).filter(word => word.length > 0);
    setBioWordCount(words.length);
  };

  useEffect(() => {
    const fetchPost = async () => {
      const docRef = doc(db, 'clipsPosts', id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const postData = docSnap.data();
        setPost(postData);
        setNickname(postData.title || '');
        setDescription(postData.description || '');
        setCity(postData.city || '');
        setState(postData.state || '');
        setCountry(postData.country || '');
        setUserLocation(postData.location || '');
        setLink(postData.link || '');
        setInstagram(postData.instagram || '');
        setTwitter(postData.twitter || '');
        setTiktok(postData.tiktok || '');
        setYoutube(postData.youtube || '');
        setFacebook(postData.facebook || '');
        setCategories(postData.categories || []);
        setShareProfile(postData.shareProfile || false);
        
        // Only set bio and username from the database if generatedBio is not present in location state
        if (!location.state?.generatedBio) {
          setBio(postData.bio || '');
          setUserName(postData.userName || '');
        }
  
        setProfileImagePreview(postData.profileImageUrl || null);
        setPostImagePreview(postData.imageUrl || null);
        setEmail(postData.email || '');
        updateWordCount(postData.description || '');
        updateBioWordCount(postData.bio || '');
      } else {
        console.log('No such document!');
        navigate('/');
      }
    };
  
    fetchPost();
  
    // Load profile information from localStorage if post data is missing it
    const savedBio = localStorage.getItem('bio');
    const savedUserName = localStorage.getItem('userName');
    const savedProfileImageUrl = localStorage.getItem('profileImageUrl');
  
    if (!post?.bio && savedBio) setBio(savedBio);
    if (!post?.userName && savedUserName) setUserName(savedUserName);
    if (!post?.profileImageUrl && savedProfileImageUrl) setProfileImagePreview(savedProfileImageUrl);
  }, [id, navigate, location.state?.generatedBio]);
  
  useEffect(() => {
    // Set the generatedBio from About.js if it exists
    const generatedBio = location.state?.generatedBio;
    if (generatedBio) {
      setBio(generatedBio); // Set the generated bio in the bio field
      updateBioWordCount(generatedBio);
      setShareProfile(true); // Automatically check the "Share Profile" box
    }
  }, [location.state?.generatedBio]);

const handleImageUpload = (imageBlob) => {
  setUploadedImageBlob(imageBlob);
  setIsImageProcessing(false);
  if (imageBlob) {
    setPostImagePreview(URL.createObjectURL(imageBlob));
  } else {
    setPostImagePreview(null);
  }
};

const handleProfileImageUpload = async (imageBlob) => {
  setProfileImageBlob(imageBlob);
  setProfileImageProcessing(false);

  if (imageBlob) {
    const imageUrl = URL.createObjectURL(imageBlob);
    setProfileImagePreview(imageUrl);
  } else {
    setProfileImagePreview(null);
  }
};

const handleDescriptionChange = (e) => {
  const newDescription = e.target.value;
  setDescription(newDescription);
  updateWordCount(newDescription);
};

const handleBioChange = (e) => {
  const newBio = e.target.value;
  setBio(newBio);
  updateBioWordCount(newBio);
};

const handleCategoryChange = (e) => {
  const { value, checked } = e.target;
  if (checked) {
    setCategories(prev => [...prev, value]);
  } else {
    setCategories(prev => prev.filter(category => category !== value));
  }
};

// Add this new function to load the most recent bio
const loadMostRecentBio = async () => {
  if (currentUser) {
    try {
      const postsRef = collection(db, 'clipsPosts');
      const q = query(
        postsRef,
        where('userId', '==', currentUser.uid),
        orderBy('timestamp', 'desc'),
        limit(1)
      );
      
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const lastPost = querySnapshot.docs[0].data();
        if (lastPost.bio) {
          setBio(lastPost.bio);
          updateBioWordCount(lastPost.bio);
        }
        if (lastPost.userName) {
          setUserName(lastPost.userName);
        }
        if (lastPost.profileImageUrl) {
          setProfileImagePreview(lastPost.profileImageUrl);
        }
      }
    } catch (error) {
      console.error('Error loading most recent bio:', error);
    }
  }
};

// Modified handleProfileCheckboxChange function
const handleProfileCheckboxChange = (e) => {
  const isChecked = e.target.checked;
  setShareProfile(isChecked);

  if (isChecked && !bio) {
    // Load the most recent bio when enabling Share Profile and no bio is present
    loadMostRecentBio();
  }

  // Save profile information to localStorage even when unchecking
  localStorage.setItem('bio', bio);
  localStorage.setItem('userName', userName);
  if (profileImagePreview) {
    localStorage.setItem('profileImageUrl', profileImagePreview);
  } else {
    localStorage.removeItem('profileImageUrl');
  }
};  

const handleSubmit = async (event) => {
  event.preventDefault();
  setIsSubmitting(true);
  setShowErrors(true);

  const form = event.target;
  const nickname = form['nickname'].value.trim();
  const description = form['description'].value.trim();
  const city = form['city'].value.trim();
  const state = form['state'].value.trim();
  const country = form['country'].value.trim();
  const locationValue = form['location'].value;
  const link = form['link'].value.trim();
  const instagram = form['instagram'].value.trim();
  const twitter = form['twitter'].value.trim();
  const tiktok = form['tiktok'].value.trim();
  const youtube = form['youtube'].value.trim();
  const facebook = form['facebook'].value.trim();
  const bio = form['bio']?.value.trim();
  const userName = form['userName']?.value.trim();
  const email = form['email']?.value.trim();

  let hasErrors = false;
  const newFieldErrors = {};

  if (!nickname) {
    newFieldErrors.nickname = 'Name is required.';
    hasErrors = true;
  }

  // Add bio/userName validation
  if (bio?.trim() && !userName?.trim()) {
    newFieldErrors.userName = 'Your name is required when adding a bio.';
    hasErrors = true;
  }

  if (!description) {
    newFieldErrors.description = 'Description is required.';
    hasErrors = true;
  }

  if (!locationValue) {
    newFieldErrors.location = 'Please select a location.';
    hasErrors = true;
  }

  if (!city) {
    newFieldErrors.city = 'City is required.';
    hasErrors = true;
  }

  if (!country) {
    newFieldErrors.country = 'Country is required.';
    hasErrors = true;
  }

  if (wordCount > 40) {
    newFieldErrors.description = 'Description cannot exceed 40 words.';
    hasErrors = true;
  }

  if (bio && bioWordCount > 40) {
    newFieldErrors.bio = 'Bio cannot exceed 40 words.';
    hasErrors = true;
  }

  if (link && !/^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/.test(link)) {
    newFieldErrors.link = 'Please enter a valid URL';
    hasErrors = true;
}

  if (shareProfile && !userName) {
    newFieldErrors.userName = 'Your name is required when sharing profile.';
    hasErrors = true;
  }

  if (email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
    newFieldErrors.email = 'Please enter a valid email address.';
    hasErrors = true;
  }

  setFieldErrors(newFieldErrors);

  if (hasErrors) {
    setIsSubmitting(false);
    return;
  }

  try {
    let imageDownloadUrl = post.imageUrl;
    if (uploadedImageBlob) {
      imageDownloadUrl = await uploadImageToStorage(
        uploadedImageBlob,
        `images/${currentUser.uid}_${new Date().toISOString()}`
      );
    }

    let profileImageDownloadUrl = post.profileImageUrl;
    if (profileImageBlob) {
      profileImageDownloadUrl = await uploadImageToStorage(
        profileImageBlob,
        `profileImages/${currentUser.uid}_${new Date().toISOString()}`
      );
    } else if (profileImagePreview === null) {
      profileImageDownloadUrl = null;
    }

    // Save profile information to localStorage regardless of shareProfile state
    localStorage.setItem('bio', bio);
    localStorage.setItem('userName', userName);
    if (profileImageDownloadUrl) {
      localStorage.setItem('profileImageUrl', profileImageDownloadUrl);
    } else {
      localStorage.removeItem('profileImageUrl'); // Remove from localStorage if no profile image
    }

    const updatedPostData = {
      title: nickname,
      description,
      city,
      state,
      country,
      location: userLocation,
      categories,
      link,
      instagram,
      twitter,
      tiktok,
      youtube,
      facebook,
      email: email || null,
      bio: shareProfile ? bio : null,
      profileImageUrl: shareProfile ? profileImageDownloadUrl : null,
      imageUrl: imageDownloadUrl,
      shareProfile,
      userName: shareProfile ? userName : null,
      lastEdited: new Date().toISOString()
    };

    const updatedPost = await updatePostInDB('clipsPosts', id, updatedPostData);

    if (updatedPost) {
      // Check if the description has changed
      if (description !== post.description) {
        // Update the summary
        const newSummary = await updatePostSummary(id, description);
        if (newSummary) {
          console.log('Post summary updated:', newSummary);
        }
      }

      updateSinglePost(updatedPost);
      navigate(`/clipsposts/${updatedPost.id}`);
    } else {
      throw new Error("Failed to update post");
    }
  } catch (error) {
    console.error("Error updating post:", error);
    setIsSubmitting(false);
  }
};  

const handleClose = () => {
  navigate(-1);
};

if (!post) {
  return <div>Loading...</div>;
}

return (
  <div className="form-container" style={{ marginTop: '150px', backgroundColor: 'white' }}>
    <CloseButton onClick={handleClose} />
    <div className="form-header">
      <h2 className="form-title" style={{ color: 'black' }}>Edit Post</h2>
    </div>
    <form className="post-form" onSubmit={handleSubmit}>
      <div className="form-group">
        <label style={{ color: 'black' }}>What Are You Sharing With Us?</label>
        <input
          type="text"
          name="nickname"
          className={`form-input ${showErrors && fieldErrors.nickname ? 'error' : ''}`}
          placeholder="The Name of what You're Sharing. Ideas are Welcome Too!"
          value={nickname}
          onChange={e => setNickname(e.target.value)}
        />
        {showErrors && fieldErrors.nickname && <p className="error-message">{fieldErrors.nickname}</p>}
      </div>
      <div className="form-group">
    <label style={{ color: 'black' }}>
        Tell Us What It's All About{' '}
        <Link to="/summary" title="Use AI to help generate your bio">(Generate a Description)</Link>
    </label>
    <textarea
        name="description"
        className={`form-input ${showErrors && fieldErrors.description ? 'error' : ''}`}
        rows="4"
        placeholder="Briefly describe what you're sharing (up to 40 words)"
        value={description}
        onChange={handleDescriptionChange}
    />
    <p className={`word-count ${wordCount > 40 ? 'word-count-exceeded' : ''}`}>
        {wordCount} / 40 words
    </p>
    {showErrors && fieldErrors.description && (
        <p className="error-message">{fieldErrors.description}</p>
    )}
    
    <p style={{ 
        color: '#666', 
        fontSize: '0.9rem', 
        marginTop: '8px',
        marginBottom: '16px',
        fontStyle: 'italic'
    }}>
        Your entire Description will appear with your Post. Also, your Post Name & an 8-10 word Summary of your Description (AI Generated after Post Creation) will be Promoted across HyroClipse Discovery Boxes to Maximize Visibility & Exposure.
    </p>
</div>
      <div className="form-group">
        <label style={{ color: 'black' }}>Select a Category (Optional)</label>
        <div className="form-options">
          {sortedCategories.map((category) => (
            <div key={category}>
              <input 
                type="checkbox" 
                id={category.toLowerCase()} 
                name="category" 
                value={category} 
                checked={categories.includes(category)}
                onChange={handleCategoryChange} 
              />
              <label htmlFor={category.toLowerCase()}>{category}</label>
            </div>
          ))}
        </div>
      </div>
      <div className="form-group">
      <label style={{ color: 'black' }}>Add a URL Link (Optional)</label>
  <input
    type="text"
    name="link"
    className={`form-input ${showErrors && fieldErrors.link ? 'error' : ''}`}
    placeholder="e.g., example.com"
    value={link}
    onChange={e => setLink(e.target.value)}
  />
  {showErrors && fieldErrors.link && <p className="error-message">{fieldErrors.link}</p>}
</div>
<div className="form-group">
<label style={{ color: 'black' }}>Share Your Socials (Optional)</label>
  <div className="social-inputs">
    <div className="social-input">
      <FaInstagram className="social-icon instagram" />
      <input
        type="text"
        name="instagram"
        className="form-input"
        placeholder="Instagram Username"
        value={instagram}
        onChange={e => setInstagram(e.target.value)}
      />
    </div>
    <div className="social-input">
      <FaTwitter className="social-icon twitter" />
      <input
        type="text"
        name="twitter"
        className="form-input"
        placeholder="Twitter/X Username"
        value={twitter}
        onChange={e => setTwitter(e.target.value)}
      />
    </div>
    <div className="social-input">
      <FaTiktok className="social-icon tiktok" />
      <input
        type="text"
        name="tiktok"
        className="form-input"
        placeholder="TikTok Username"
        value={tiktok}
        onChange={e => setTiktok(e.target.value)}
      />
    </div>
    <div className="social-input">
      <FaYoutube className="social-icon youtube" />
      <input
        type="text"
        name="youtube"
        className="form-input"
        placeholder="YouTube Channel Handle"
        value={youtube}
        onChange={e => setYoutube(e.target.value)}
      />
    </div>
    <div className="social-input">
      <FaFacebook className="social-icon facebook" />
      <input
        type="text"
        name="facebook"
        className="form-input"
        placeholder="Facebook Username"
        value={facebook}
        onChange={e => setFacebook(e.target.value)}
      />
    </div>
  </div>
</div>
<div className="form-group">
  <label style={{ color: 'black' }}>Add Your Contact Email (Optional)</label>
  <input
    type="email"
    name="email"
    className={`form-input ${showErrors && fieldErrors.email ? 'error' : ''}`}
    placeholder="e.g., your@email.com"
    value={email}
    onChange={e => setEmail(e.target.value)}
  />
  {showErrors && fieldErrors.email && <p className="error-message">{fieldErrors.email}</p>}
</div>
<div className="form-group">
  <label style={{ color: 'black' }}>Where Are You Based?</label>
  <div className="form-options">
    {['North America', 'Europe', 'Africa', 'Middle East', 'Asia', 'Central America', 'South America', 'Australia'].map((loc) => (
      <div key={loc}>
        <input 
          type="radio" 
          id={loc.toLowerCase().replace(' ', '')} 
          name="location" 
          value={loc} 
          checked={userLocation === loc}
          onChange={e => setUserLocation(e.target.value)} 
        />
        <label htmlFor={loc.toLowerCase().replace(' ', '')}>{loc}</label>
      </div>
    ))}
  </div>
  {showErrors && fieldErrors.location && <p className="error-message">{fieldErrors.location}</p>}
</div>
<div className="form-group">
  <label style={{ color: 'black' }}>City</label>
  <input
    type="text"
    name="city"
    className={`form-input ${showErrors && fieldErrors.city ? 'error' : ''}`}
    value={city}
    onChange={e => setCity(e.target.value)}
  />
  {showErrors && fieldErrors.city && <p className="error-message">{fieldErrors.city}</p>}
</div>
<div className="form-group">
  <label style={{ color: 'black' }}>State (Optional)</label>
  <input
    type="text"
    name="state"
    className="form-input"
    value={state}
    onChange={e => setState(e.target.value)}
  />
</div>
<div className="form-group">
  <label style={{ color: 'black' }}>Country</label>
  <input
    type="text"
    name="country"
    className={`form-input ${showErrors && fieldErrors.country ? 'error' : ''}`}
    value={country}
    onChange={e => setCountry(e.target.value)}
  />
  {showErrors && fieldErrors.country && <p className="error-message">{fieldErrors.country}</p>}
</div>
<MyDropzone
  onImageUpload={handleImageUpload}
  setIsImageProcessing={setIsImageProcessing}
  setProgress={setProgress}
  setUploadError={setUploadError}
  initialImage={postImagePreview}
/>
{uploadError && <p className="error-message">{uploadError}</p>}
{isImageProcessing && (
  <div className="progress-bar-container">
    <progress value={progress} max="100"></progress>
  </div>
)}
<div className="form-group">
  <label>
    <input
      type="checkbox"
      checked={shareProfile}
      onChange={handleProfileCheckboxChange}
    />
    Share Your Profile
  </label>
</div>
{shareProfile && (
  <>
    <div className="form-group">
      <label style={{ color: 'black' }}>Your Name</label>
      <input
        type="text"
        name="userName"
        className={`form-input ${showErrors && fieldErrors.userName ? 'error' : ''}`}
        placeholder="Enter your name"
        value={userName}
        onChange={(e) => setUserName(e.target.value)}
      />
      {showErrors && fieldErrors.userName && <p className="error-message">{fieldErrors.userName}</p>}
    </div>
    <div className="form-group">
      <label style={{ color: 'black' }}>
        Brief Bio <Link to="/about" state={{ returnTo: `/editclipspost/${id}` }} className="generate-bio-link">(Generate Your Bio)</Link>
      </label>
      <textarea
        name="bio"
        className={`form-input ${showErrors && bioWordCount > 40 ? 'error' : ''}`}
        rows="4"
        placeholder="Enter a brief bio (max 40 words)"
        value={bio}
        onChange={handleBioChange}
      />
      <p className={`word-count ${bioWordCount > 40 ? 'word-count-exceeded' : ''}`}>
        {bioWordCount} / 40 words
      </p>
      {showErrors && bioWordCount > 40 && (
        <p className="error-message">Bio cannot exceed 40 words.</p>
      )}
    </div>
    <div className="form-group">
    <label style={{ color: 'black' }}>Profile Image</label>
        <ProfileImageDropzone
          onImageUpload={handleProfileImageUpload}
          setIsImageProcessing={setProfileImageProcessing}
          setProgress={setProfileImageProgress}
          setUploadError={setProfileImageError}
          initialImage={profileImagePreview}
        />
        {profileImageError && <p className="error-message">{profileImageError}</p>}
        {profileImageProcessing && (
          <div className="progress-bar-container">
            <progress value={profileImageProgress} max="100"></progress>
          </div>
        )}
      </div>
    </>
  )}
  {Object.values(fieldErrors).some(error => error) && (
    <div className="error-container">
      {Object.entries(fieldErrors).map(([field, error]) => (
        error && <p key={field} className="error-message">{error}</p>
      ))}
    </div>
  )}
  <div className="error-container">
    {isSubmitting && <p className="error-message">Submitting...</p>}
  </div>
  <button type="submit" className="form-button" disabled={isImageProcessing || isSubmitting}>
    {isSubmitting ? 'Updating...' : 'Update Post'}
  </button>
</form>
</div>
);
};

export default ClipsEditPostForm;





