// ClipsLikesPage.js

import React, { useContext, useEffect, useState } from 'react';
import ClipsPost from './ClipsPost';
import { FaHeart } from 'react-icons/fa';
import { useAuth } from '../contexts/AuthContext';
import { ClipsPostsContext } from '../contexts/ClipsPostsContext';
import { useClipsLikes } from '../contexts/ClipsLikesContext';
import './MyPostsPage.css'; // Reuse the same CSS as ClipsMyPostsPage

const ClipsLikesPage = () => {
  const { user } = useAuth();
  const { posts } = useContext(ClipsPostsContext);
  const { userLikes } = useClipsLikes();
  const [topMargin, setTopMargin] = useState('120px');

  useEffect(() => {
    window.scrollTo(0, 0);

    const updateTopMargin = () => {
      if (window.innerWidth === 1264) {
        setTopMargin('140px'); // Match top margin with ClipsMyPostsPage
      } else if (window.innerWidth <= 768) {
        setTopMargin('200px'); // Match top margin for smaller screens
      } else {
        setTopMargin('120px'); // Default margin
      }
    };

    window.addEventListener('resize', updateTopMargin);
    updateTopMargin();

    return () => window.removeEventListener('resize', updateTopMargin);
  }, []);

  const likedPosts = posts.filter(post => userLikes.includes(post.id));

  return (
    <div className="my-posts-container" style={{ marginTop: topMargin }}>
      <h1 style={{ textAlign: 'center' }}><FaHeart /> Likes</h1>
      {likedPosts.length > 0 ? (
        likedPosts.map(post =>
          <div key={post.id} className="post-container">
            <ClipsPost 
              id={post.id}
              title={post.title}
              author={post.author}
              description={post.description}
              categories={post.categories}
              imageUrl={post.imageUrl}
              timestamp={post.timestamp}
              city={post.city}             
              state={post.state}           
              country={post.country}       
              location={post.location}
              link={post.link}
              instagram={post.instagram}
              twitter={post.twitter}
              tiktok={post.tiktok}
              youtube={post.youtube}
            />
          </div>
        )
      ) : (
        <p>You haven't liked any posts yet.</p>
      )}
    </div>
  );
};

export default ClipsLikesPage;
