// LocationPosts.js

import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ClipsPostsContext } from '../contexts/ClipsPostsContext';
import ClipsPost from './ClipsPost';  // Import the ClipsPost component
import { Typography } from '@mui/material';  // Import the Typography component
import './UsersPosts.css';  // Importing UsersPosts.css for styling

const LocationPosts = () => {
    const { location } = useParams();
    const { posts } = useContext(ClipsPostsContext);
    const [locationPosts, setLocationPosts] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top when the component mounts
        
        if (location && posts.length > 0) {
            const filteredPosts = posts.filter(post => post.location && post.location === location);
            setLocationPosts(filteredPosts);
        }
    }, [location, posts]);

    return (
        <div className="users-posts-container"> {/* Using the same class for consistent styling */}
            <h1><span className="location-link">{location}</span></h1>
            {locationPosts.length > 0 ? (
                locationPosts.map(post => (
                    <ClipsPost 
                        key={post.id}
                        id={post.id}
                        title={post.title}
                        author={post.author}
                        description={post.description}
                        location={post.location}
                        imageUrl={post.imageUrl}
                        timestamp={post.timestamp}
                        city={post.city}
                        state={post.state}
                        country={post.country}
                        categories={post.categories}
                        link={post.link}
                        instagram={post.instagram}
                        twitter={post.twitter}
                        tiktok={post.tiktok}
                        youtube={post.youtube}
                    />
                ))
            ) : (
                <Typography variant="body1" color="text.secondary">
                    No posts found in this location.
                </Typography>
            )}
        </div>
    );
};

export default LocationPosts;


