// About.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import './PostForm.css';

function About() {
  const [prompt, setPrompt] = useState('');
  const [generatedBio, setGeneratedBio] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const returnTo = location.state?.returnTo || '/';

  useEffect(() => {
    // Load saved inputted text from localStorage if available
    const savedInputtedText = localStorage.getItem('bioInputtedText') || '';
    setPrompt(savedInputtedText);
  }, []);

  const handlePromptChange = (e) => {
    setPrompt(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    setGeneratedBio('');

    // Save inputted text to localStorage
    localStorage.setItem('bioInputtedText', prompt);

    const apiKey = process.env.REACT_APP_OPENAI_KEY;

    if (!apiKey) {
      setError('OpenAI API key is not configured. Please check your .env file.');
      setIsLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: "gpt-4o-mini",
          messages: [
            {
              role: "system",
              content: "You are a helpful assistant that generates concise and engaging biographies of approximately 40 words."
            },
            {
              role: "user",
              content: `Generate an approximately 40-word biography for ${prompt}. The biography should be engaging and highlight key aspects of the person or organization.`
            }
          ],
          max_tokens: 100,
          temperature: 0.25,
          n: 1,
        },
        {
          headers: {
            'Authorization': `Bearer ${apiKey}`,
            'Content-Type': 'application/json',
          }
        }
      );

      let generatedText = response.data.choices[0].message.content.trim() || '';

      if (typeof generatedText !== 'string') {
        setError('The generated bio is not a valid string.');
        setIsLoading(false);
        return;
      }

      const words = generatedText.split(/\s+/);
      if (words.length > 40) {
        generatedText = words.slice(0, 40).join(' ');
      }

      setGeneratedBio(generatedText);
    } catch (error) {
      console.error('Error generating bio:', error);
      setError('Failed to generate bio. Please try again.');
      setGeneratedBio('');
    }

    setIsLoading(false);
  };

  const handleUseThisBioClick = () => {
    // Navigate back to ClipsPostForm.js and pass the generated bio for the "Share Profile" section
    navigate(returnTo, { state: { generatedBio } });
  };

  return (
    <div className="form-container" style={{ marginTop: '150px', backgroundColor: 'white' }}>
      <h2 className="form-title" style={{ color: 'black' }}>Your Bio, Reimagined by Artificial Intelligence</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label style={{ color: 'black' }}>Share Your Story With Us</label>
          <textarea
            name="prompt"
            className="form-input"
            rows="4"
            placeholder="E.g., I'm a passionate software developer with 5 years of experience..."
            value={prompt}
            onChange={handlePromptChange}
          />
        </div>
        <button type="submit" className="form-button" disabled={isLoading}>
          {isLoading ? 'Crafting Your Bio...' : 'Generate My Bio'}
        </button>
      </form>
      {error && <p className="error-message">{error}</p>}
      {generatedBio && (
        <div>
          <h3 className="summary-title" style={{ color: 'black' }}>Your AI-Crafted Bio:</h3>
          <p className="generated-summary" style={{ color: '#292929' }}>{generatedBio}</p>
          <p className="word-count" style={{ color: 'black' }}>{generatedBio.split(' ').length} words</p>
          <button onClick={handleUseThisBioClick} className="form-button" style={{ marginTop: '20px' }}>
            Use This Bio
          </button>
        </div>
      )}
    </div>
  );
}

export default About;
