// ClipsManagePosts.js

import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { doc, updateDoc } from "firebase/firestore";
import { db } from '../utils/firebaseConfig';
import './ManagePosts.css';
import { ClipsPostsContext } from '../contexts/ClipsPostsContext';

const ClipsManagePosts = () => {
  const { 
    posts: globalPosts, 
    deletePostById, 
    updateSinglePost, 
    fetchMorePosts, 
    loading, 
    hasMore 
  } = useContext(ClipsPostsContext);
  const [posts, setPosts] = useState([]);

  // Update to filter out deleted posts
  useEffect(() => {
    setPosts(globalPosts.filter(post => !post.deleted));
  }, [globalPosts]);

  // Infinite scroll: Fetch more posts when scrolling near bottom
  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + window.scrollY >= document.body.scrollHeight - 200 && !loading && hasMore) {
        fetchMorePosts();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loading, hasMore, fetchMorePosts]);

  const handleDelete = async (postId) => {
    if (window.confirm("Are you sure you want to delete this post?")) {
      await deletePostById(postId);
    }
  };

  const handleShowOnHomePageChange = async (post, showOnHomePage) => {
    const postRef = doc(db, "clipsPosts", post.id);
    await updateDoc(postRef, { showOnHomePage });
    updateSinglePost({ ...post, showOnHomePage });
  };

  const handleShowInDiscoverBoxChange = async (post, showInDiscoverBox) => {
    const postRef = doc(db, "clipsPosts", post.id);
    await updateDoc(postRef, { showInDiscoverBox });
    updateSinglePost({ ...post, showInDiscoverBox });
  };

  return (
    <div>
      <h2>Manage Posts</h2>
      <div>Total Posts: {posts.length}</div>
      <ul className="post-list">
        {posts.map(post => (
          <li key={post.id} className="post-item">
            {post.imageUrl && (
              <img src={post.imageUrl} alt={post.title || "Post image"} className="post-thumbnail" />
            )}
            <Link to={`/clipsposts/${post.id}`} className="post-title">{post.title}</Link>
            
            <div>
              <label>
                <input 
                  type="checkbox" 
                  checked={post.showOnHomePage || false} 
                  onChange={(e) => handleShowOnHomePageChange(post, e.target.checked)} 
                />
                Show on Home Page
              </label>
            </div>

            <div>
              <label>
                <input 
                  type="checkbox" 
                  checked={post.showInDiscoverBox || false} 
                  onChange={(e) => handleShowInDiscoverBoxChange(post, e.target.checked)} 
                />
                Show in Discover Posts Box
              </label>
            </div>

            <button onClick={() => handleDelete(post.id)} className="button-delete">Delete</button>
          </li>
        ))}
      </ul>

      {loading && <p>Loading more posts...</p>}
    </div>
  );
}

export default ClipsManagePosts;
