// Footer.js

import React, { useContext, useEffect, useState } from 'react';
import './Footer.css';
import { useNavigate } from 'react-router-dom';
import { IoHomeOutline, IoAddCircleOutline, IoCompassOutline } from 'react-icons/io5';
import { MdFiberNew } from 'react-icons/md';
import { useAuth } from '../contexts/AuthContext';
import ActiveItemContext from '../contexts/ActiveItemContext';

const Footer = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();
  const { setActiveItem } = useContext(ActiveItemContext);
  const [isVisible, setIsVisible] = useState(window.innerWidth <= 1180);

  useEffect(() => {
    const handleResize = () => {
      setIsVisible(window.innerWidth <= 1180);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call initially to set the correct state
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handlePostClick = (e) => {
    e.preventDefault();
    if (!isLoggedIn) {
      navigate('/login');
      setActiveItem('Login');
      return;
    }
    
    setActiveItem('Post');
    navigate('/clipspostform');
  };

  if (!isVisible) {
    return null;
  }

  return (
    <footer className="app-footer">
      <div className="footer-icons">
        <div onClick={() => {
          setActiveItem('Home');
          navigate('/');
        }} className="footer-icon">
          <IoHomeOutline />
        </div>
        <div onClick={handlePostClick} className="footer-icon">
          <IoAddCircleOutline />
        </div>
        <div onClick={() => {
          setActiveItem('New Posts');
          navigate('/newposts');
        }} className="footer-icon footer-icon--new">
          <MdFiberNew />
        </div>
        <div onClick={() => {
          setActiveItem('Explore');
          navigate('/explore');
        }} className="footer-icon">
          <IoCompassOutline />
        </div>
      </div>
    </footer>
  );
}

export default Footer;
