// StatePosts.js

import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ClipsPostsContext } from '../contexts/ClipsPostsContext';
import ClipsPost from './ClipsPost';
import { Typography } from '@mui/material';
import './UsersPosts.css';

const StatePosts = () => {
    const { state } = useParams();
    const { posts } = useContext(ClipsPostsContext);
    const [statePosts, setStatePosts] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top when the component mounts
        
        if (state && posts.length > 0) {
            const filteredPosts = posts.filter(post => post.state && post.state === state);
            setStatePosts(filteredPosts);
        }
    }, [state, posts]);

    return (
        <div className="users-posts-container">
            <h1><span className="location-link">{state}</span></h1>
            {statePosts.length > 0 ? (
                statePosts.map(post => (
                    <ClipsPost 
                        key={post.id}
                        id={post.id}
                        title={post.title}
                        author={post.author}
                        description={post.description}
                        location={post.location}
                        imageUrl={post.imageUrl}
                        timestamp={post.timestamp}
                        city={post.city}
                        state={post.state}
                        country={post.country}
                        categories={post.categories}
                        link={post.link}
                        instagram={post.instagram}
                        twitter={post.twitter}
                        tiktok={post.tiktok}
                        youtube={post.youtube}
                    />
                ))
            ) : (
                <Typography variant="body1" color="text.secondary">
                    No posts found in this state.
                </Typography>
            )}
        </div>
    );
};

export default StatePosts;
