// ClipsViewReports.js

import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ClipsReportsContext } from '../contexts/ClipsReportsContext';
import { ClipsPostsContext } from '../contexts/ClipsPostsContext';
import { getPostById, getUserById, markPostAsReviewed, markReportAsReviewed, markReportAsDeleted } from '../utils/dbUtilities';
import moment from 'moment-timezone';
import './ViewReports.css';

const ClipsViewReports = () => {
    const { reports } = useContext(ClipsReportsContext);
    const { updateReportedStatus, deletePostById } = useContext(ClipsPostsContext);
    const [reportDetails, setReportDetails] = useState([]);

    useEffect(() => {
        const fetchReportDetails = async () => {
            let details = await Promise.all(reports.map(async (report) => {
                const post = await getPostById(report.postId, 'clipsPosts');
                const user = await getUserById(report.userId);
                const timestamp = report.timestamp ? new Date(report.timestamp).getTime() : null;
                return {
                    ...report,
                    postTitle: post ? post.title : 'Title not found',
                    username: user ? user.username : 'Username not found',
                    postLink: post ? `/clipsposts/${report.postId}` : null,
                    reviewed: report.reviewed || false, // Set reviewed status from the report data
                    postDeleted: report.postDeleted || false, // Set postDeleted status from the report data
                    timestamp,
                };
            }));
        
            details.sort((a, b) => {
                if (!a.timestamp) return 1;
                if (!b.timestamp) return -1;
                return b.timestamp - a.timestamp;
            });
        
            setReportDetails(details);
        };        

        fetchReportDetails();
    }, [reports]);

    const handleMarkAsReviewed = async (postId, reportId) => {
        try {
            await markPostAsReviewed(postId, 'clipsPosts');
            await updateReportedStatus(postId, false, 'clipsPosts');
            await markReportAsReviewed(reportId, 'clipsReports');
    
            setReportDetails(prevDetails =>
                prevDetails.map(detail =>
                    detail.id === reportId ? { ...detail, reviewed: true } : detail
                )
            );
        } catch (error) {
            console.error('Error marking post as reviewed:', error);
        }
    };    

    const handleDeletePost = async (postId, reportId) => {
        try {
            if (window.confirm("Are you sure you want to delete this post?")) {
                await deletePostById(postId, 'clipsPosts');
                await markReportAsDeleted(reportId, 'clipsReports');

                setReportDetails(prevDetails =>
                    prevDetails.map(detail =>
                        detail.id === reportId ? { ...detail, postDeleted: true } : detail
                    )
                );
            }
        } catch (error) {
            console.error('Error deleting post:', error);
        }
    };

    const formatTimestamp = (timestamp) => {
        return moment(timestamp).tz('America/New_York').format('YYYY-MM-DD HH:mm:ss');
    };

    const renderText = (text) => {
        return typeof text === 'string' ? text : 'Invalid details';
    };

    const renderReason = (reason) => {
        return typeof reason === 'string' ? reason : 'Invalid reason';
    };

    return (
        <div className="view-reports-container">
            <h2>View Reports</h2>
            <div className="reports-list">
                {reportDetails.map((detail) => (
                    <div key={detail.id} className="report-item">
                        <p>Type: {detail.type}</p>
                        <p>ID: {detail.id}</p>
                        <p>Reported By: {detail.username}</p>
                        <p>Reported At: {formatTimestamp(detail.timestamp)}</p>
                        <p>Reason: {renderReason(detail.reason)}</p>
                        <p>Details: {renderText(detail.text)}</p>
                        {detail.postLink && (
                            <p>Post Title: <Link to={detail.postLink}>{detail.postTitle}</Link></p>
                        )}
                        <div className="report-action">
                            {detail.postDeleted ? (
                                <p>Post Deleted</p>
                            ) : detail.reviewed ? (
                                <p>Post Marked as Reviewed</p>
                            ) : (
                                <>
                                    <button onClick={() => handleMarkAsReviewed(detail.postId, detail.id)}>
                                        Mark as Reviewed
                                    </button>
                                    <button onClick={() => handleDeletePost(detail.postId, detail.id)}>
                                        Delete Post
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ClipsViewReports;
