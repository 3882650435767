// ClipsReportsContext.js

import React, { createContext, useState, useEffect, useContext } from 'react';
import AuthContext from './AuthContext';
import { getAllReportsFromDB, reportClipPost, reportClipUser } from '../utils/dbUtilities';

export const ClipsReportsContext = createContext();

export const ClipsReportsProvider = ({ children }) => {
  const [reports, setReports] = useState([]);
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    const loadReports = async () => {
      try {
        const fetchedReports = await getAllReportsFromDB('clipsReports'); // Fetch reports for clips specifically
        setReports(fetchedReports);
      } catch (error) {
        console.error("Error fetching reports:", error);
      }
    };

    loadReports();
  }, []);

  const reportPost = async (reportDetails) => {
    try {
      if (!currentUser) {
        throw new Error('User not authenticated');
      }
      const newReport = {
        ...reportDetails,
        userId: currentUser.uid, // Set the user ID of the reporter
      };
      const reportId = await reportClipPost(newReport); // Ensure this writes to 'clipsReports'
      setReports(prevReports => [...prevReports, { ...newReport, id: reportId }]);
    } catch (error) {
      console.error('Error reporting post:', error);
    }
  };

  const reportUser = async (userId, details) => {
    try {
      if (!currentUser) {
        throw new Error('User not authenticated');
      }
      const reportData = {
        ...details,
        userId, // ID of the user being reported
        reporterId: currentUser.uid, // Current user's ID
        type: 'user', // Adding a type property to distinguish user reports
        timestamp: new Date() // Timestamp of the report
      };
      await reportClipUser(userId, reportData);
      setReports(prevReports => [...prevReports, reportData]);
    } catch (error) {
      console.error('Error reporting user:', error);
    }
  };

  return (
    <ClipsReportsContext.Provider value={{ reports, reportPost, reportUser }}>
      {children}
    </ClipsReportsContext.Provider>
  );
};

export default ClipsReportsContext;
