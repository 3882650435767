// MessagesPage.js

import React, { useEffect } from 'react';
import MessagesList from './MessagesList';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './MessagesPage.css';
import { useMessages } from '../contexts/MessagesContext';

function MessagesPage() {
  const navigate = useNavigate();
  const { fetchMessages, isLoading } = useMessages();

  useEffect(() => {
    fetchMessages();
    window.scrollTo(0, 0);
  }, []);

  const handleNewChat = () => {
    navigate('/chat');
  };

  if (isLoading) {
    return <div>Loading messages...</div>;
  }

  return (
    <div className="messages-container">
      <div className="messages-content-wrapper">
        <div className="header">
          {/* header content */}
        </div>
        <div className="main-content">
          <MessagesList />
          <div className="new-chat-button-section">
            <Button variant="contained" color="primary" onClick={handleNewChat}>
              New Chat
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MessagesPage;
