// VisibilityTracker.js

import React, { useEffect, useRef } from 'react';
import { doc, setDoc, increment } from 'firebase/firestore';
import { db } from '../utils/firebaseConfig';

const VisibilityTracker = ({ postId, children, isDiscoveryBox }) => {
    const ref = useRef(null);
    const observerRef = useRef(null);

    useEffect(() => {
        if (!isDiscoveryBox) return;

        observerRef.current = new IntersectionObserver(
            async ([entry]) => {
                if (entry.isIntersecting) {
                    const impressionRef = doc(db, 'discoveryBoxImpressions', postId);
                    try {
                        await setDoc(impressionRef, { count: increment(1) }, { merge: true });
                        observerRef.current.unobserve(ref.current);
                    } catch (error) {
                        console.error('Error updating impression count:', error);
                    }
                }
            },
            {
                threshold: 0.5,
            }
        );

        if (ref.current) {
            observerRef.current.observe(ref.current);
        }

        return () => {
            if (observerRef.current && ref.current) {
                observerRef.current.unobserve(ref.current);
                observerRef.current.disconnect();
            }
        };
    }, [postId, isDiscoveryBox]);

    return <div ref={ref}>{children}</div>;
};

export default VisibilityTracker;