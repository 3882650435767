// ClipsLikesContext.js

import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { db } from '../utils/firebaseConfig';
import { collection, query, where, onSnapshot, addDoc, deleteDoc, getDocs } from 'firebase/firestore';

const ClipsLikesContext = createContext();

export const ClipsLikesProvider = ({ children }) => {
    const { currentUser } = useAuth();
    const [userLikes, setUserLikes] = useState([]);

    useEffect(() => {
        let unsubscribe = () => {};
        if (currentUser) {
            const likesQuery = query(collection(db, 'clipsLikes'), where('userId', '==', currentUser.uid));
            unsubscribe = onSnapshot(likesQuery, (snapshot) => {
                const likes = snapshot.docs.map(doc => doc.data().postId);
                setUserLikes(likes);
            });
        }
        return () => unsubscribe();
    }, [currentUser]);

    const addLike = async (postId) => {
        if (currentUser) {
            const likeData = { userId: currentUser.uid, postId, timestamp: new Date() };
            await addDoc(collection(db, 'clipsLikes'), likeData);
        }
    };

    const removeLike = async (postId) => {
        if (currentUser) {
            const likeQuery = query(collection(db, 'clipsLikes'), where('postId', '==', postId), where('userId', '==', currentUser.uid));
            const snapshot = await getDocs(likeQuery);
            const likeDoc = snapshot.docs[0];
            if (likeDoc) {
                await deleteDoc(likeDoc.ref);
            }
        }
    };

    return (
        <ClipsLikesContext.Provider value={{ userLikes, addLike, removeLike }}>
            {children}
        </ClipsLikesContext.Provider>
    );
};

export const useClipsLikes = () => useContext(ClipsLikesContext);
