// Feedback.js

import React, { useEffect, useState } from 'react';
import { db } from '../utils/firebaseConfig';
import { doc, getDoc, setDoc, runTransaction } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';
import { useFeedback } from '../contexts/FeedbackContext';
import './Feedback.css';

const baseFeedbackOptions = [
  "Love It!",
  "Really Like It!",
  "Mixed Feelings",
  "Needs Improvement",
  "I Don't Understand"
];

function sanitizeOption(option) {
  return option.replace(/[^\w\s]/gi, '').replace(/\s+/g, '_');
}

function Feedback({ postId }) {
  const { currentUser } = useAuth();
  const { userFeedback, feedbackCounts, addFeedback, removeFeedback, getFeedbackCounts } = useFeedback();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch feedback options and counts
    const fetchFeedbackData = async () => {
      try {
        const feedbackDocRef = doc(db, 'clipsPosts', postId, 'feedback', 'options');
        const feedbackDoc = await getDoc(feedbackDocRef);

        if (!feedbackDoc.exists() || !arraysEqual(feedbackDoc.data().options, baseFeedbackOptions)) {
          await setDoc(feedbackDocRef, { options: baseFeedbackOptions });
        }

        // Fetch the counts for each feedback option
        await getFeedbackCounts(postId);
      } catch (error) {
        console.error('Error fetching feedback data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchFeedbackData();
  }, [postId, getFeedbackCounts]);

  const handleFeedbackClick = async (option) => {
    if (!currentUser) {
      alert('Please log in to provide feedback.');
      return;
    }

    const sanitizedOption = sanitizeOption(option);
    const isSelected = userFeedback[postId] && userFeedback[postId][sanitizedOption];

    try {
      if (isSelected) {
        await removeFeedback(postId, sanitizedOption);
      } else {
        await addFeedback(postId, sanitizedOption);
      }
    } catch (error) {
      console.error('Error updating feedback selection:', error);
    }
  };

  if (loading) {
    return <p>Loading feedback options...</p>;
  }

  return (
    <div className="feedback-container">
      {baseFeedbackOptions.map((option, index) => {
        const sanitizedOption = sanitizeOption(option);
        const isSelected = userFeedback[postId] && userFeedback[postId][sanitizedOption];
        const count = feedbackCounts[postId] && feedbackCounts[postId][sanitizedOption] || 0;

        return (
          <button
            key={index}
            className={`feedback-option ${isSelected ? 'selected' : ''}`}
            onClick={() => handleFeedbackClick(option)}
          >
            {option}
            <span className="feedback-count">{count}</span>
          </button>
        );
      })}
    </div>
  );
}

function arraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;
  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

export default Feedback;


