// InfoPage.js

import React, { useEffect } from 'react';
import './InfoPage.css';

const InfoPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top when the component mounts
    }, []);

    return (
        <div className="info-container">
            <section className="welcome-section">
                <h1>Welcome to HyroClipse</h1>
                <p>HyroClipse is a platform that empowers users to showcase themselves, their projects, and their businesses through personalized posts. By sharing a brief description, personal bio, and location, users can effectively reach new audiences and build their following without relying on traditional advertising methods.</p>
                
                <p>Key features of HyroClipse include:</p>
                <ul style={{ paddingLeft: '20px' }}>
                    <li>Posting images and descriptions to highlight your unique offerings</li>
                    <li>Sharing your website URL and social media handles to drive traffic and engagement</li>
                    <li>Connecting with users in your local area to foster community and generate interest</li>
                    <li>Providing a free and user-friendly alternative to Google or Facebook ads</li>
                </ul>
            </section>

            <section className="terms-of-use">
                <h2>Terms of Use</h2>
                <p>HyroClipse, a service provided by Hyroglf, LLC, is designed to facilitate a unique user-driven content sharing experience. By accessing or interacting with our servers, services, website, or mobile application ("App"), you agree to these Terms of Use ("TOU"). Your use of HyroClipse signifies your acceptance of these TOU. Please note that HyroClipse is a private platform owned by Hyroglf, LLC. If you represent a business using HyroClipse, you confirm your authority to bind that business to these TOU.</p>
                
                <p>Hyroglf, LLC and HyroClipse are not responsible for any transactions, agreements, or interactions that may result from connections made on the platform. Users are solely responsible for their interactions and any resulting outcomes.</p>
                
                <h3>License</h3>
                <p>Hyroglf, LLC grants you a limited, revocable, non-exclusive, non-assignable license to use HyroClipse in compliance with these TOU. Unauthorized use is not permitted. You are prohibited from misusing any content from HyroClipse, except for content you personally create.</p>
                
                <h3>Usage Policy</h3>
                <p>You agree to use HyroClipse responsibly and lawfully, adhering to the prohibited content guidelines outlined below. You must respect all moderation efforts and the privacy and rights of other users.</p>
                
                <h3>Prohibited Content</h3>
                <p>The following content is strictly prohibited on HyroClipse:</p>
                <ul style={{ paddingLeft: '20px' }}>
                    <li>Adult Content: No promotion of adult content or services.</li>
                    <li>Discrimination: No content that discriminates based on personal characteristics.</li>
                    <li>Misleading, Violent, or Hateful Content: No misleading, violent, or hateful content.</li>
                    <li>Illegal Activities: No promotion of illegal goods, services, or activities.</li>
                    <li>Intellectual Property Infringement: No infringement of intellectual property rights.</li>
                    <li>Spam: No spamming or posting of irrelevant content.</li>
                    <li>Impersonation: No impersonation of other individuals or entities.</li>
                </ul>
            </section>

            <section className="disclaimer-section">
                <h2>Disclaimer & Liability</h2>
                <p>HyroClipse, provided "AS IS" by Hyroglf, LLC, comes without warranties of any kind. Hyroglf, LLC is not liable for any damages or losses resulting from your use of HyroClipse or from interactions arising from its use.</p>
            </section>

            <section className="claims-section">
                <h2>Claims & Indemnity</h2>
                <p>Any disputes related to these TOU will be governed by the laws of the jurisdiction where Hyroglf, LLC is located. You agree to indemnify Hyroglf, LLC against any claims related to your use of HyroClipse.</p>
            </section>

            <section className="miscellaneous-section">
                <h2>Miscellaneous</h2>
                <p>These TOU constitute the entire agreement between you and Hyroglf, LLC regarding HyroClipse. Changes to these TOU will be effective upon posting, and your continued use of HyroClipse signifies acceptance of these changes.</p>
            </section>
        </div>
    );
}

export default InfoPage;
