// ProfileContext.js

import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { getUserById, updateUserBio, uploadImageToStorage } from '../utils/dbUtilities';

const ProfileContext = createContext();

export const useProfile = () => useContext(ProfileContext);

export const ProfileProvider = ({ children }) => {
  const { currentUser } = useAuth();
  const [profileData, setProfileData] = useState(null);

  useEffect(() => {
    const fetchProfileData = async () => {
      if (currentUser) {
        const userData = await getUserById(currentUser.uid);
        setProfileData(userData);
      }
    };
    fetchProfileData();
  }, [currentUser]);

  const updateProfileImage = async (imageUrl) => {
    if (currentUser) {
      await updateUserBio(currentUser.uid, { profileImageUrl: imageUrl });
      setProfileData((prevData) => ({ ...prevData, profileImageUrl: imageUrl }));
    }
  };

  return (
    <ProfileContext.Provider value={{ profileData, updateProfileImage }}>
      {children}
    </ProfileContext.Provider>
  );
};
