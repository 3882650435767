// ClipsContactForm.js

import React, { useState, useEffect } from 'react';
import { AppBar, Box, Button, Container, TextField, Typography } from '@mui/material';
import moment from 'moment-timezone';
import { saveClipsContactSubmission } from '../utils/dbUtilities';
import { useAuth } from '../contexts/AuthContext';
import './ContactForm.css';

const ClipsContactForm = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [submissionStatus, setSubmissionStatus] = useState('');
    const [mathChallenge, setMathChallenge] = useState({});
    const [mathAnswer, setMathAnswer] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);

    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [messageError, setMessageError] = useState('');
    const [mathAnswerError, setMathAnswerError] = useState('');

    const { currentUser } = useAuth();

    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top when the component mounts

        if (!isSubmitted) {
            generateMathChallenge();
        }
    }, [isSubmitted]);

    const generateMathChallenge = () => {
        const num1 = Math.floor(Math.random() * 10);
        const num2 = Math.floor(Math.random() * 10);
        setMathChallenge({ num1, num2 });
    };

    const getUsername = () => {
        return currentUser ? (currentUser.username || currentUser.displayName || currentUser.email.split('@')[0]) : null;
    };

    const isValidEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let isValid = true;

        if (!name.trim()) {
            setNameError('Please enter your name.');
            isValid = false;
        } else {
            setNameError('');
        }

        if (!isValidEmail(email)) {
            setEmailError('Please enter a valid email address.');
            isValid = false;
        } else {
            setEmailError('');
        }

        if (!message.trim()) {
            setMessageError('Please enter your message.');
            isValid = false;
        } else {
            setMessageError('');
        }

        if (parseInt(mathAnswer) !== mathChallenge.num1 + mathChallenge.num2) {
            setMathAnswerError('Incorrect math CAPTCHA answer.');
            isValid = false;
        } else {
            setMathAnswerError('');
        }

        if (isValid) {
            const timestampEST = moment().tz('America/New_York').format('YYYY-MM-DD HH:mm:ss');
            const submissionData = {
                name, 
                email, 
                message, 
                timestamp: timestampEST,
                username: getUsername()
            };

            try {
                await saveClipsContactSubmission(submissionData); // Save to the HyroClips collection
                setSubmissionStatus('Your message has been sent successfully!');
                setIsSubmitted(true);
                setName('');
                setEmail('');
                setMessage('');
                setMathAnswer('');
            } catch (error) {
                setSubmissionStatus('Failed to send message. Please try again.');
            }
        } else {
            generateMathChallenge();
        }
    };

    return (
        <div className="contact-form-container">
            <Container component="main" maxWidth="sm" className="contact-form">
                <AppBar position="static" color="default" className="contact-form-header">
                    <Typography variant="h6">Contact Us</Typography>
                </AppBar>
                <form onSubmit={handleSubmit} noValidate>
                    <Box my={3}>
                        <TextField
                            label="Name"
                            variant="outlined"
                            fullWidth
                            required
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            error={!!nameError}
                            helperText={nameError}
                            margin="normal"
                        />
                    </Box>
                    <Box my={3}>
                        <TextField
                            label="Email"
                            variant="outlined"
                            fullWidth
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            error={!!emailError}
                            helperText={emailError}
                            margin="normal"
                        />
                    </Box>
                    <Box my={3}>
                        <TextField
                            label="Message"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                            required
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            error={!!messageError}
                            helperText={messageError}
                            margin="normal"
                        />
                    </Box>
                    {!isSubmitted && (
                        <>
                            <Typography>
                                Solve this to prove you're not a robot: {mathChallenge.num1} + {mathChallenge.num2} = ?
                            </Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                required
                                value={mathAnswer}
                                onChange={(e) => setMathAnswer(e.target.value)}
                                error={!!mathAnswerError}
                                helperText={mathAnswerError}
                                margin="normal"
                                InputProps={{
                                    style: { textAlign: 'center' },
                                }}
                            />
                        </>
                    )}
                    {submissionStatus && (
                        <Typography color="primary" style={{ textAlign: 'center', marginTop: '20px' }}>
                            {submissionStatus}
                        </Typography>
                    )}
                    <Box display="flex" justifyContent="center" my={3}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="large"
                        >
                            Send
                        </Button>
                    </Box>
                </form>
            </Container>
        </div>
    );      
};

export default ClipsContactForm;

