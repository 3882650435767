// ChatWindow.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Box, AppBar, Typography, IconButton, Container, TextareaAutosize, Snackbar, TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getUserByUsername, generateConversationId, checkConversationExists, createConversation } from '../utils/dbUtilities';
import { getAllUsernames } from '../utils/getAllUsernames';
import { useMessages } from '../contexts/MessagesContext';
import { useAuth } from '../contexts/AuthContext';

const ChatWindow = ({ initialRecipient, onClose, defaultMessage = "This is Great!", showChatWindow, isStandalone }) => {
  const [newMessage, setNewMessage] = useState(defaultMessage);
  const [recipient, setRecipient] = useState(initialRecipient || "");
  const [allUsernames, setAllUsernames] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const { sendMessage } = useMessages();
  const { currentUser: authUser } = useAuth();
  const navigate = useNavigate();

  const [mathChallenge, setMathChallenge] = useState({
    num1: Math.floor(Math.random() * 10),
    num2: Math.floor(Math.random() * 10)
  });
  const [mathAnswer, setMathAnswer] = useState('');
  const [captchaError, setCaptchaError] = useState('');

  useEffect(() => {
    if (!authUser) {
      console.log("User is not authenticated, redirecting to login");
      navigate('/login');
    }
  }, [authUser, navigate]);

  useEffect(() => {
    const fetchUsernames = async () => {
      try {
        const usernames = await getAllUsernames();
        setAllUsernames(usernames);
      } catch (error) {
        console.error("Error fetching usernames:", error);
      }
    };
    fetchUsernames();
  }, []);

  useEffect(() => {
    if (initialRecipient) {
      setRecipient(initialRecipient);
    }
  }, [initialRecipient]);

  useEffect(() => {
    generateMathChallenge();
  }, []);

  const generateMathChallenge = () => {
    const num1 = Math.floor(Math.random() * 10);
    const num2 = Math.floor(Math.random() * 10);
    setMathChallenge({ num1, num2 });
  };

  const handleInputChange = (event, newValue) => {
    setRecipient(newValue);
  };

  const handleSendMessage = async () => {
    if (parseInt(mathAnswer) !== mathChallenge.num1 + mathChallenge.num2) {
      setCaptchaError('Incorrect answer to math problem.');
      generateMathChallenge();
      return;
    }

    if (!newMessage.trim()) {
      setSnackbarMessage("Message cannot be empty.");
      setSnackbarOpen(true);
      return;
    }

    if (!recipient) {
      setSnackbarMessage("Please select a recipient.");
      setSnackbarOpen(true);
      return;
    }

    const senderIdentifier = authUser.displayName || authUser.username || authUser.email.split('@')[0];
    const recipientIdentifier = recipient;

    try {
      const recipientData = await getUserByUsername(recipientIdentifier);
      if (!recipientData) {
        setSnackbarMessage(`Username "${recipientIdentifier}" does not exist!`);
        setSnackbarOpen(true);
        return;
      }

      let conversationId = generateConversationId(senderIdentifier, recipientIdentifier);
      const conversationExists = await checkConversationExists(conversationId);
      if (!conversationExists) {
        await createConversation(conversationId, [senderIdentifier, recipientIdentifier]);
      }

      const messageData = {
        text: newMessage.trim(),
        timestamp: new Date().toISOString(),
        read: false,
        sender: senderIdentifier,
        receiver: recipientIdentifier,
        conversationId
      };

      await sendMessage(recipientIdentifier, messageData);
      setNewMessage("");
      navigate(`/chat/${recipientIdentifier}`);
    } catch (error) {
      console.error("Error sending message: ", error);
      setSnackbarMessage("Failed to send message. Please try again.");
      setSnackbarOpen(true);
    }
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  // Define styles for the outermost div
  const parentDivStyles = {
    display: 'flex',
    justifyContent: 'center', // Keep the chat window centered
    width: '100%', // Ensure the div takes full width
    marginRight: isStandalone ? '40px' : '0', // Shift the content to the right if standalone
  };

// Styles are defined outside of the return
const getDynamicStyles = () => {
  const largeScreenWidth = 768; // Breakpoint for larger screens
  const maxWidthForStandalone = '400px'; // Max width for standalone chat window on large screens
  const maxWidthForEmbedded = '600px'; // Max width for embedded chat window on large screens

  // Determine the current screen width
  const screenWidth = window.innerWidth;

  // Define the default styles for the ChatWindow
  const styles = {
    backgroundColor: '#fff',
    padding: '40px',
    borderRadius: '20px',
    boxShadow: '0 5px 15px rgba(0,0,0,0.1)',
    maxWidth: isStandalone
      ? (screenWidth >= largeScreenWidth ? maxWidthForStandalone : '100%')
      : (screenWidth >= largeScreenWidth ? maxWidthForEmbedded : '100%'),
    marginRight: isStandalone ? '40px' : '0',
    // Adjust this value as needed for both small and large screens
    marginTop: screenWidth >= largeScreenWidth ? '100px' : '150px', // Lowered top margin for large screens
    marginBottom: screenWidth >= largeScreenWidth ? '50px' : '0',
  };

  return styles;
};

  return (
    <div style={parentDivStyles}>
      <Container maxWidth="lg" style={getDynamicStyles()}>
        <AppBar position="static" color="default" style={{ padding: '15px', borderRadius: '20px', marginBottom: '20px', boxShadow: 'none' }}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">New Message</Typography>
            <IconButton edge="end" color="inherit" aria-label="close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </AppBar>
        <Box my={3}>
          <Autocomplete
            freeSolo
            options={allUsernames}
            getOptionLabel={(option) => option}
            value={recipient}
            onInputChange={handleInputChange}
            filterOptions={(options, { inputValue }) => {
              const maxResults = 5;
              return inputValue.length >= 2
                ? options
                    .filter((option) => option.toLowerCase().startsWith(inputValue.toLowerCase()))
                    .slice(0, maxResults)
                : [];
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                variant="outlined"
                label="To:"
                InputProps={{
                  ...params.InputProps,
                  style: {
                    borderRadius: '15px',
                  },
                }}
              />
            )}
          />
        </Box>

        <Box my={3}>
          <TextareaAutosize
            minRows={3}
            maxRows={5}
            style={{
              width: '100%',
              padding: '20px',
              fontSize: '1rem',
              fontFamily: 'Roboto, Arial, sans-serif',
              borderRadius: '15px',
              border: '1px solid #e0e0e0',
              outline: 'none',
              resize: 'none',
            }}
            placeholder={defaultMessage}
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
          />
        </Box>

        <Box my={3}>
          <div>
            Solve this to prove you're not a robot: {mathChallenge.num1} + {mathChallenge.num2} = ?
          </div>
          <TextField
            fullWidth
            variant="outlined"
            value={mathAnswer}
            onChange={(e) => setMathAnswer(e.target.value)}
            error={!!captchaError}
            helperText={captchaError}
            sx={{
              '& .MuiInputBase-input': {
                textAlign: 'center',
              },
            }}
          />
        </Box>

        <Box display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            size="large"
            style={{ borderRadius: '15px' }}
            onClick={handleSendMessage}
          >
            Send
          </Button>
        </Box>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarMessage}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          action={
            <IconButton size="small" color="inherit" onClick={() => setSnackbarOpen(false)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
      </Container>
    </div>
  );
};

export default ChatWindow;
