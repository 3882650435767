// Summary.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import './PostForm.css';

function Summary() {
  const [description, setDescription] = useState('');
  const [summary, setSummary] = useState('');
  const [wordCount, setWordCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // Load saved inputted text from localStorage if available
    const savedInputtedText = localStorage.getItem('inputtedText') || '';
    setDescription(savedInputtedText);
  }, []);

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    // Save inputted text to localStorage
    localStorage.setItem('inputtedText', description);

    const apiKey = process.env.REACT_APP_OPENAI_KEY;

    if (!apiKey) {
      setError('OpenAI API key is not configured. Please check your .env file.');
      setIsLoading(false);
      return;
    }

    try {
      const isSummarize = description.split(' ').length > 24;

      const examples = `Example 1: 
Text: "African Fabric Furniture and Sustainable Offerings for West African Markets

Global trends are pointing towards a fusion of sustainability, increased consumer spending, and a blend of traditional with the contemporary. The dynamic West African market is poised to be at the forefront of this shift. 

Market Potential

Demographic Surge: With Africa's population set to touch 2.5 billion by 2050, becoming the largest working-age populace, the demand for home essentials, especially furniture, is predicted to rise significantly.

Consumer Spending Growth: McKinsey highlights the potential of Africa unlocking a staggering $3 trillion in consumer spending by 2030. With 250 million Africans stepping into the consuming class, there's a burgeoning demand for products that are both innovative and sustainable.

Urbanization & Economic Momentum: Rapid urbanization combined with a projected addition of $1.4 trillion to Africa's economy from productivity growth emphasizes the escalating demand for urban lifestyle essentials.

The Kaiyo Influence

The success story of U.S-based Kaiyo provides pivotal insights:

Sustainability is Non-Negotiable: With furniture becoming a substantial contributor to landfill waste, Kaiyo's commitment to keeping waste out of landfills points to a substantial market demand for sustainable furniture alternatives.

Consumer Behavior: Making the buying and selling of second-hand furniture convenient and environmentally responsible is crucial. Kaiyo's 100% YoY growth is a testament to the soaring interest in the circular economy.

The Market's Potential: The furniture resale sector is set to jump from $9.9 billion in 2018 to a striking $16.6 billion by 2025, underscoring the vast opportunity awaiting.

African Fabric Furniture Launch Products

Accent Chairs: Merging the opulence of African fabrics with modern design, our accent chairs will be a symbol of luxury and heritage.

Benches: These will offer a blend of utility and aesthetic appeal, capturing the essence of African artistry.

Slip Covers: Our slip covers made from intricate African fabrics will provide consumers an eco-friendly option to revamp their existing furniture pieces.

Sustainable Second-Hand Furniture: Inspired by Kaiyo's model, second-hand furniture will champion sustainability. Revamped with African fabrics, these pieces will not only promote eco-friendliness but also resonate with cultural pride.

Conclusion:
The amalgamation of tradition, sustainability, and technology is shaping the future of furniture retail. As highlighted by Eric Kouskalis of MoKo, reaching millions of African families is just the beginning."

Caption: "EMG Designs offers sustainable furniture with African fabrics. We're targeting West Africa's booming market with tradition and modern design meeting rising consumer demands."

Example 2
Text: "My board game is called Billions Below. The goal is to create a faster, more fun alternative to Monopoly."

Caption: "Introducing 'Billions Below,' a thrilling board game. Enjoy a quicker, more engaging experience than Monopoly with dynamic gameplay."

Example 3
Text: "My board game is called Billions Below. The goal is to create a faster, more fun alternative to Monopoly. It's a very fun, fast paced game! Also, players learn more about the geo-political risks that come with oil exploration in developing countries."

Caption: "Billions Below offers a fun, fast-paced board game alternative to Monopoly. Learn about geo-political risks in oil exploration within developing countries."
`;

      const userPrompt = isSummarize
        ? `Summarize the following text into an engaging caption, consisting of 20-24 words in 2-3 complete, coherent sentences. The final output must be 20-24 words long. Follow the format of the examples provided:

${examples}

Text: "${description}"

Caption:`
        : `Expand the following text into an engaging caption, consisting of 20-24 words in 2-3 complete, coherent sentences. The final output must be 20-24 words long. Follow the format of the examples provided:

${examples}

Text: "${description}"

Caption:`;

      // Updated API call to use gpt-4o-mini and chat completion endpoint
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-4o-mini',
          messages: [
            {
              role: 'system',
              content: 'You are an assistant that generates concise and engaging captions.'
            },
            {
              role: 'user',
              content: userPrompt
            }
          ],
          max_tokens: 50,
          temperature: 0.25,
          n: 1,
          stop: ['\n'],
        },
        {
          headers: {
            'Authorization': `Bearer ${apiKey}`,
            'Content-Type': 'application/json',
          }
        }
      );

      // Extract generated text from the updated response structure
      let generatedText = response.data.choices[0].message.content.trim();
      generatedText = generatedText.replace(/^"(.*)"$/, '$1'); // Remove quotes
      const words = generatedText.split(' ');

      if (words.length < 20 || words.length > 24) {
        const fallbackPrompt = `The previous output was not 20-24 words. Please regenerate the caption, ensuring it is exactly 20-24 words in 2-3 coherent sentences:

Caption: "${generatedText}"

New caption:`;

        // Updated fallback API call
        const fallbackResponse = await axios.post(
          'https://api.openai.com/v1/chat/completions',
          {
            model: 'gpt-4o-mini',
            messages: [
              {
                role: 'system',
                content: 'You are an assistant that generates concise and engaging captions.'
              },
              {
                role: 'user',
                content: fallbackPrompt
              }
            ],
            max_tokens: 50,
            temperature: 0.25,
            n: 1,
            stop: ['\n'],
          },
          {
            headers: {
              'Authorization': `Bearer ${apiKey}`,
              'Content-Type': 'application/json',
            }
          }
        );

        generatedText = fallbackResponse.data.choices[0].message.content.trim();
        generatedText = generatedText.replace(/^"(.*)"$/, '$1'); // Remove quotes
      }

      setSummary(generatedText);
      setWordCount(generatedText.split(' ').length);
    } catch (error) {
      console.error('Error generating summary:', error);
      if (error.response) {
        console.error('API response:', error.response.data);
        setError(`API error: ${error.response.data.error.message}`);
      } else {
        setError('Network error. Please check your internet connection.');
      }
    }

    setIsLoading(false);
  };

  const handleGoClick = () => {
    navigate('/clipspostform', { state: { suggestionText: summary } });
  };

  if (!currentUser) {
    navigate('/login');
    return null;
  }

  return (
    <div className="form-container" style={{ marginTop: '200px', backgroundColor: 'white' }}>
      <h2 className="form-title" style={{ color: 'black' }}>AI Description Generator: Craft Your Perfect Description</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label style={{ color: 'black' }}>Describe Your Post: AI Will Enhance It</label>
          <textarea
            name="description"
            className="form-input"
            rows="4"
            placeholder="Enter a description of your creation"
            value={description}
            onChange={handleDescriptionChange}
          />
        </div>
        <button type="submit" className="form-button" disabled={isLoading}>
          {isLoading ? 'Generating Suggestions...' : 'Get AI Suggestions'}
        </button>
      </form>
      {error && <p className="error-message">{error}</p>}
      {summary && (
        <div>
          <h3 className="summary-title" style={{ color: 'black' }}>AI-Generated Suggestion:</h3>
          <p className="generated-summary" style={{ color: '#292929' }}>{summary}</p>
          <p className="word-count" style={{ color: 'black' }}>{wordCount} words</p>
          <button onClick={handleGoClick} className="form-button" style={{ marginTop: '20px' }}>
            Use This Suggestion
          </button>
        </div>
      )}
    </div>
  );
}

export default Summary;
