// ClipsUsersPosts.js

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ClipsPost from './ClipsPost';
import { FaUser } from 'react-icons/fa';
import { getAllDataFromDB } from '../utils/dbUtilities';
import './UsersPosts.css';

function ClipsUsersPosts() {
    const [userPosts, setUserPosts] = useState([]);
    const { username } = useParams();

    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top when the component mounts
        
        async function fetchUserPosts() {
            try {
                const allPosts = await getAllDataFromDB('clipsPosts');  // Use the 'clipsPosts' collection
                const specificUserPosts = allPosts
                    .filter(post => post.author === username)
                    .map(post => ({
                        ...post,
                        timestamp: post.timestamp?.toDate ? post.timestamp.toDate() : new Date(post.timestamp)
                    }))
                    .sort((a, b) => b.timestamp - a.timestamp);  // Sorting from newest to oldest
                setUserPosts(specificUserPosts);
            } catch (error) {
                console.error("Error fetching posts:", error);
            }
        }
    
        fetchUserPosts();
    }, [username]);

    return (
        <div className="users-posts-container">
            <h1><FaUser /> Posts by {username}</h1>
            {userPosts.map(post => 
                <ClipsPost 
                    key={post.id}
                    id={post.id}
                    title={post.title}
                    author={post.author}
                    description={post.description}
                    location={post.location}
                    imageUrl={post.imageUrl}  // No placeholder if no imageUrl is present
                    timestamp={post.timestamp}
                    city={post.city}
                    state={post.state}
                    country={post.country}
                    categories={post.categories}
                    link={post.link}
                    instagram={post.instagram}
                    twitter={post.twitter}
                    tiktok={post.tiktok}
                    youtube={post.youtube}
                />
            )}
        </div>
    );
}

export default ClipsUsersPosts;
