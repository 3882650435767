// utils/firebaseConfig.js

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage'; // Import getStorage
import { getAnalytics } from 'firebase/analytics'; 

const firebaseConfig = {
  apiKey: "AIzaSyA15RDyEhIrrpyqukoH9A3RQuJqcE8qZlQ",
  authDomain: "hyrovault.firebaseapp.com",
  projectId: "hyrovault",
  storageBucket: "hyrovault.appspot.com",
  messagingSenderId: "123683151448",
  appId: "1:123683151448:web:8bb4737e2d8f5f9e7ef42d",
  measurementId: "G-2PQ0ERQH2G"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize other Firebase services
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);

// Initialize Firebase Analytics
export const analytics = getAnalytics(app);

export default app;