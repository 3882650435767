// FeedbackContext.js

import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { db } from '../utils/firebaseConfig';
import { collection, query, where, onSnapshot, addDoc, deleteDoc, getDocs, doc, getDoc, setDoc, runTransaction } from 'firebase/firestore';

const FeedbackContext = createContext();

export const FeedbackProvider = ({ children }) => {
    const { currentUser } = useAuth();
    const [userFeedback, setUserFeedback] = useState({});
    const [feedbackCounts, setFeedbackCounts] = useState({});

    useEffect(() => {
        let unsubscribe = () => {};
        if (currentUser) {
            const feedbackQuery = query(collection(db, 'feedback'), where('userId', '==', currentUser.uid));
            unsubscribe = onSnapshot(feedbackQuery, (snapshot) => {
                const feedback = {};
                snapshot.forEach(doc => {
                    const { postId, option } = doc.data();
                    if (!feedback[postId]) {
                        feedback[postId] = {};
                    }
                    feedback[postId][option] = true;
                });
                setUserFeedback(feedback);
            });
        }
        return () => unsubscribe();
    }, [currentUser]);

    const addFeedback = async (postId, option) => {
        if (currentUser) {
            const feedbackData = { userId: currentUser.uid, postId, option, timestamp: new Date() };
            await addDoc(collection(db, 'feedback'), feedbackData);
            await updateFeedbackCount(postId, option, 1);
        }
    };

    const removeFeedback = async (postId, option) => {
        if (currentUser) {
            const feedbackQuery = query(collection(db, 'feedback'), where('postId', '==', postId), where('userId', '==', currentUser.uid), where('option', '==', option));
            const snapshot = await getDocs(feedbackQuery);
            const feedbackDoc = snapshot.docs[0];
            if (feedbackDoc) {
                await deleteDoc(feedbackDoc.ref);
                await updateFeedbackCount(postId, option, -1);
            }
        }
    };

    const updateFeedbackCount = async (postId, option, increment) => {
        const feedbackCountRef = doc(db, 'feedbackCounts', postId);
        await runTransaction(db, async (transaction) => {
            const feedbackCountDoc = await transaction.get(feedbackCountRef);
            if (!feedbackCountDoc.exists()) {
                transaction.set(feedbackCountRef, { [option]: increment });
            } else {
                const count = feedbackCountDoc.data()[option] || 0;
                const newCount = count + increment;
                transaction.update(feedbackCountRef, { [option]: newCount >= 0 ? newCount : 0 });
            }
        });
    };

    const getFeedbackCounts = async (postId) => {
        const feedbackCountRef = doc(db, 'feedbackCounts', postId);
        const feedbackCountDoc = await getDoc(feedbackCountRef);
        if (feedbackCountDoc.exists()) {
            setFeedbackCounts(prevCounts => ({
                ...prevCounts,
                [postId]: feedbackCountDoc.data(),
            }));
        }
    };

    return (
        <FeedbackContext.Provider value={{ userFeedback, feedbackCounts, addFeedback, removeFeedback, getFeedbackCounts }}>
            {children}
        </FeedbackContext.Provider>
    );
};

export const useFeedback = () => useContext(FeedbackContext);

