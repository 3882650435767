// ClipsAdminDashboard.js

import React, { useState, useEffect } from 'react';
import ManageUsers from './ManageUsers'; 
import ClipsViewReports from './ClipsViewReports'; 
import { getAllClipsContactSubmissions, updatePostVerificationPassword } from '../utils/dbUtilities';
import './AdminDashboard.css'; 

import ClipsManagePosts from './ClipsManagePosts'; // Direct import instead of lazy loading

const ClipsAdminDashboard = () => {
    const [activeTab, setActiveTab] = useState('posts');
    const [contactSubmissions, setContactSubmissions] = useState([]);
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    useEffect(() => {
        getAllClipsContactSubmissions().then(submissions => {
            const sortedSubmissions = submissions.sort((a, b) => b.timestamp - a.timestamp);
            setContactSubmissions(sortedSubmissions);
        }).catch(error => {
            console.error('Failed to load contact submissions:', error);
        });
    }, []);

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    const getTabClass = (tabName) => {
        return `admin-dashboard-tab-button ${activeTab === tabName ? 'active' : ''}`;
    };

    return (
        <div className="admin-dashboard-container">
            <div className="admin-dashboard-header">
                <h1>Clips Admin Dashboard</h1>
            </div>
            <ul className="admin-dashboard-tabs">
                <li className={getTabClass('posts')} onClick={() => handleTabClick('posts')}>Manage Posts</li>
                <li className={getTabClass('users')} onClick={() => handleTabClick('users')}>Manage Users</li>
                <li className={getTabClass('reports')} onClick={() => handleTabClick('reports')}>View Reports</li>
                <li className={getTabClass('contact')} onClick={() => handleTabClick('contact')}>Contact Submissions</li>
                <li className={getTabClass('password')} onClick={() => handleTabClick('password')}>Post Verification Password</li>
            </ul>
            <div className="admin-dashboard-content">
                {/* Suspense not needed since we're no longer lazy loading */}
                {activeTab === 'posts' && <ClipsManagePosts />}
                {activeTab === 'users' && <ManageUsers />}
                {activeTab === 'reports' && <ClipsViewReports />}
                {activeTab === 'contact' && (
                    <div>
                        <h2>Contact Form Submissions</h2>
                        <div className="reports-list">
                            {contactSubmissions.map(submission => (
                                <div key={submission.id} className="report-item">
                                    <p>From: {submission.name} ({submission.email})</p>
                                    <p>Message: {submission.message}</p>
                                    <p>Sent: {new Date(submission.timestamp).toLocaleString()}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                {activeTab === 'password' && (
                    <div>
                        <h2>Change Post Verification Password</h2>
                        <div>
                            <label>
                                New Password:
                                <input
                                    type="password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    placeholder="Enter new password"
                                />
                            </label>
                        </div>
                        <div>
                            <label>
                                Confirm New Password:
                                <input
                                    type="password"
                                    value={confirmNewPassword}
                                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                                    placeholder="Confirm new password"
                                />
                            </label>
                        </div>
                        <button onClick={() => updatePostVerificationPassword(newPassword)}>Update Password</button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ClipsAdminDashboard;


