// ClipsSearchResultsPage.js

import React, { useState, useEffect, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import ClipsPost from './ClipsPost';  // Ensure this imports ClipsPost.js
import { ClipsPostsContext } from '../contexts/ClipsPostsContext';  // Ensure this imports ClipsPostsContext
import './Post.css';

const ClipsSearchResultsPage = () => {
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get('query');
  const { posts } = useContext(ClipsPostsContext);  // Fetch posts from ClipsPostsContext
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const filterPosts = () => {
      setIsLoading(true);
      setError(null);

      try {
        const queryLower = searchQuery ? searchQuery.toLowerCase() : '';
        const filteredResults = posts.filter(post =>
          post.title.toLowerCase().includes(queryLower) ||
          post.author.toLowerCase().includes(queryLower) ||
          (post.description && post.description.toLowerCase().includes(queryLower))
        );

        setResults(filteredResults);
      } catch (error) {
        setError('Error filtering search results');
      } finally {
        setIsLoading(false);
      }
    };

    if (searchQuery) {
      filterPosts();
    }
  }, [searchQuery, posts]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="search-results-container">
      {results.length === 0 ? (
        <p>No results found for "{searchQuery}"</p>
      ) : (
        results.map(result => (
          <div key={result.id} className="post-container">
            <ClipsPost {...result} />
          </div>
        ))
      )}
    </div>
  );
};

export default ClipsSearchResultsPage;
