// RandomPost.js

import React, { useContext, useState, useEffect } from 'react';
import { Box, Typography, IconButton, List, ListItem, Divider, Paper, Fade } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ClipsPostsContext } from '../contexts/ClipsPostsContext';
import { savePostSummary, getPostSummaries } from '../utils/dbUtilities';
import axios from 'axios';
import RefreshIcon from '@mui/icons-material/Refresh';

const RandomPost = () => {
  const { posts } = useContext(ClipsPostsContext);
  const [summaries, setSummaries] = useState(() => {
    const savedSummaries = localStorage.getItem('postSummaries');
    return savedSummaries ? JSON.parse(savedSummaries) : {};
  });
  const navigate = useNavigate();
  const [clickedPosts, setClickedPosts] = useState(() => {
    const saved = localStorage.getItem('clickedPosts');
    return saved ? JSON.parse(saved) : {};
  });

  useEffect(() => {
    loadSummaries(posts);
  }, [posts]);

  useEffect(() => {
    const handleStorage = () => {
      const saved = localStorage.getItem('clickedPosts');
      if (saved) {
        setClickedPosts(JSON.parse(saved));
      }
    };

    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, []);

  useEffect(() => {
    localStorage.setItem('postSummaries', JSON.stringify(summaries));
  }, [summaries]);

  const loadSummaries = async (allPosts) => {
    const postIds = allPosts.map((post) => post.id);
    const loadedSummaries = await getPostSummaries(postIds);
    setSummaries(prevSummaries => ({...prevSummaries, ...loadedSummaries}));
    generateMissingSummaries(allPosts, loadedSummaries);
  };

  const generateMissingSummaries = async (allPosts, existingSummaries) => {
    const apiKey = process.env.REACT_APP_OPENAI_KEY;

    if (!apiKey) {
      console.error('OpenAI API key is not configured. Please check your .env file.');
      return;
    }

    for (const post of allPosts) {
      if (!existingSummaries[post.id] && !summaries[post.id]) {
        try {
          const response = await axios.post(
            'https://api.openai.com/v1/chat/completions',
            {
              model: 'gpt-4o-mini',
              messages: [
                {
                  role: 'system',
                  content: 'You are an assistant that generates concise summaries that closely resemble the original text.'
                },
                {
                  role: 'user',
                  content: `Summarize the following text in 8-10 words as a complete sentence, capturing the essence of the original text:\n\n${post.description}`
                }
              ],
              max_tokens: 20,
              temperature: 0.7,
              n: 1,
              stop: ['\n'],
            },
            {
              headers: {
                'Authorization': `Bearer ${apiKey}`,
                'Content-Type': 'application/json',
              }
            }
          );

          const generatedText = response.data.choices[0].message.content.trim();
          setSummaries(prevSummaries => ({
            ...prevSummaries,
            [post.id]: generatedText,
          }));
          await savePostSummary(post.id, generatedText);
        } catch (error) {
          console.error("Error generating summary:", error);
          setSummaries(prevSummaries => ({
            ...prevSummaries,
            [post.id]: 'Error generating summary',
          }));
        }
      }
    }
  };

  const getLocationString = (post) => {
    if (post.city && post.state) return `(${post.city}, ${post.state})`;
    if (post.city) return `(${post.city})`;
    if (post.state) return `(${post.state})`;
    return '';
  };

  const handlePostClick = (postId) => {
    const updatedClickedPosts = { ...clickedPosts, [postId]: true };
    setClickedPosts(updatedClickedPosts);
    localStorage.setItem('clickedPosts', JSON.stringify(updatedClickedPosts));
    navigate(`/clipsposts/${postId}`);
  };

  return (
    <Fade in={true} timeout={800}>
      <Paper
        elevation={3}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          p: 4,
          mt: { xs: 12, sm: 14, md: 16 },
          maxWidth: '800px',
          margin: '0 auto',
          ml: { xs: '10%', sm: '15%', md: '20%' },
          borderRadius: '16px',
          backgroundColor: '#fff',
          overflow: 'hidden',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', mb: 4 }}>
          <Typography variant="h4" sx={{ fontWeight: '700', color: '#1d1d1f' }}>
            Discover Posts
          </Typography>
          <IconButton 
            onClick={loadSummaries} 
            sx={{ 
              color: '#007aff',
              backgroundColor: '#f5f5f7',
              '&:hover': { backgroundColor: '#e8e8ed' },
              transition: 'background-color 0.3s ease',
            }}
          >
            <RefreshIcon />
          </IconButton>
        </Box>

        {posts.length === 0 ? (
          <Typography variant="body1" sx={{ color: '#86868b' }}>No posts available.</Typography>
        ) : (
          <List sx={{ width: '100%', padding: 0 }}>
            {posts.map((post, index) => (
              <Fade in={true} timeout={500 + index * 100} key={post.id}>
                <ListItem 
                  sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'flex-start',
                    padding: '16px 0',
                    transition: 'background-color 0.3s ease',
                    '&:hover': { backgroundColor: '#f5f5f7' },
                    borderRadius: '8px',
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mb: 1 }}>
                    <Typography
                      variant="body1"
                      component="span"
                      onClick={() => handlePostClick(post.id)}
                      sx={{
                        color: clickedPosts[post.id] ? '#551A8B' : '#007aff',
                        textDecoration: 'none',
                        cursor: 'pointer',
                        fontWeight: 'bold',
                        '&:hover': { textDecoration: 'underline' },
                        fontSize: '1.1rem',
                        marginBottom: '4px',
                      }}
                    >
                      {post.title}
                    </Typography>
                    <Typography variant="body2" component="span" sx={{ color: '#86868b', fontSize: '0.9rem' }}>
                      {getLocationString(post)}
                    </Typography>
                  </Box>
                  <Typography variant="body2" sx={{ color: '#1d1d1f', fontWeight: 400, fontSize: '0.95rem' }}>
                    {summaries[post.id] || 'Generating summary...'}
                  </Typography>
                </ListItem>
              </Fade>
            ))}
          </List>
        )}
      </Paper>
    </Fade>
  );
};

export default RandomPost;

