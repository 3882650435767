// ContactList.js

import React, { useState, useContext, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { ContactListContext } from '../contexts/ContactListContext';
import TextField from '@mui/material/TextField';
import './ContactList.css'; // Create and import a new CSS file for ContactList.js

const ContactList = ({ postId, closeModal }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [submissionConfirmed, setSubmissionConfirmed] = useState(false);
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');

  const { currentUser } = useAuth();
  const { addContactSubmission } = useContext(ContactListContext);

  useEffect(() => {
    const savedName = localStorage.getItem('contactListName');
    const savedEmail = localStorage.getItem('contactListEmail');
    if (savedName) setName(savedName);
    if (savedEmail) setEmail(savedEmail);
  }, []);

  const isValidEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!currentUser) {
      setError('User not authenticated.');
      return;
    }

    let isValid = true;

    if (!name.trim()) {
      setNameError('Please enter your name.');
      isValid = false;
    } else {
      setNameError('');
    }

    if (!isValidEmail(email)) {
      setEmailError('Please enter a valid email address.');
      isValid = false;
    } else {
      setEmailError('');
    }

    if (!isValid) {
      return;
    }
  
    const contactDetails = {
      postId,
      userId: currentUser.uid,
      name,
      email,
      timestamp: new Date().toISOString(),
    };
  
    try {
      await addContactSubmission(contactDetails);
      localStorage.setItem('contactListName', name);
      localStorage.setItem('contactListEmail', email);
      setSubmissionConfirmed(true);
    } catch (error) {
      console.error('Error saving contact details:', error);
      setError('Error occurred while saving the contact details.');
    }
  };  

  if (submissionConfirmed) {
    return (
      <div className="modal-background">
        <div className="contact-list-modal">
          <div className="submission-confirmation">
            <h2>Thank you for joining the contact list!</h2>
            <p>Your submission has been received.</p>
            <button onClick={closeModal}>Close</button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="modal-background">
      <div className="contact-list-modal">
        <div className="contact-list-header">
          <button onClick={closeModal} className="close-modal-button">✕</button>
          <h2 style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            Sign Up for Email Updates!
          </h2>
        </div>
        <form onSubmit={handleSubmit} className="contact-list-form">
          <TextField
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            fullWidth
            margin="normal"
            error={!!nameError}
            helperText={nameError}
          />
          <TextField
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            fullWidth
            margin="normal"
            error={!!emailError}
            helperText={emailError}
          />
          {error && <p className="error-message">{error}</p>}
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <button type="submit" className="small-contact-list-button">Sign Up</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactList;
