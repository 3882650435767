// HomePage.js

import React, { useContext, useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import ClipsPost from './ClipsPost';
import { ClipsPostsContext } from '../contexts/ClipsPostsContext';
import { useAuth } from '../contexts/AuthContext';
import './HomePage.css';
import { Box, Paper, List, ListItem, Typography, Fade, IconButton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import VisibilityTracker from './VisibilityTracker';
import { getTotalDiscoveryBoxImpressions } from '../utils/dbUtilities';

const HomePage = () => {
  const { posts } = useContext(ClipsPostsContext);
  const { user: authUser } = useAuth();
  const navigate = useNavigate();
  const [clickedPosts, setClickedPosts] = useState(() => {
    const saved = localStorage.getItem('clickedPosts');
    return saved ? JSON.parse(saved) : {};
  });
  const [randomPostsKey, setRandomPostsKey] = useState(0);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [userBios, setUserBios] = useState({});
  const [clickedUserNames, setClickedUserNames] = useState(() => {
    const saved = localStorage.getItem('clickedUserNames');
    return saved ? JSON.parse(saved) : {};
  });
  const [randomBiosKey, setRandomBiosKey] = useState(0);
  const [totalImpressions, setTotalImpressions] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [authUser]);

  useEffect(() => {
    const fetchTotalImpressions = async () => {
      const impressions = await getTotalDiscoveryBoxImpressions();
      setTotalImpressions(impressions);
    };
  
    fetchTotalImpressions();
  }, []);
  
  useEffect(() => {
    const handleStorage = () => {
      const savedPosts = localStorage.getItem('clickedPosts');
      const savedUserNames = localStorage.getItem('clickedUserNames');
      if (savedPosts) {
        setClickedPosts(JSON.parse(savedPosts));
      }
      if (savedUserNames) {
        setClickedUserNames(JSON.parse(savedUserNames));
      }
    };
  
    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, []);
  
  useEffect(() => {
    aggregateRecentUserBios(posts);
  }, [posts]);
  
  const handlePostClick = (postId) => {
    const updatedClickedPosts = { ...clickedPosts, [postId]: true };
    setClickedPosts(updatedClickedPosts);
    localStorage.setItem('clickedPosts', JSON.stringify(updatedClickedPosts));
    navigate(`/clipsposts/${postId}`);
  };
  
  const handleRefresh = () => {
    setIsInitialLoad(false);
    setRandomPostsKey(prevKey => prevKey + 1);
  };
  
  const handleBioRefresh = () => {
    setRandomBiosKey(prevKey => prevKey + 1);
  };
  
  const uniquePosts = Array.from(new Map(posts.map(post => [post.id, post])).values());
  const discoverPosts = uniquePosts.filter(post => post.showInDiscoverBox);
  
  const randomDiscoverPosts = useMemo(() => {
    if (isInitialLoad) {
      return discoverPosts.filter(post => post.title === "HyroVault" || post.title === "HyroFits");
    } else {
      const shuffledPosts = [...discoverPosts];
      for (let i = shuffledPosts.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledPosts[i], shuffledPosts[j]] = [shuffledPosts[j], shuffledPosts[i]];
      }
      return shuffledPosts.slice(0, Math.min(5, shuffledPosts.length));
    }
  }, [discoverPosts, randomPostsKey, isInitialLoad]);
  
  const aggregateRecentUserBios = (allPosts) => {
    const bios = {};
    allPosts
      .filter((post) => post.author && post.bio && post.bio.trim() !== '')
      .sort((a, b) => b.timestamp - a.timestamp)
      .forEach((post) => {
        if (!bios[post.author]) {
          bios[post.author] = {
            bio: post.bio,
            city: post.city || '',
            state: post.state || '',
            name: post.userName || '',
          };
        }
      });
    setUserBios(bios);
  };
  
  const truncateBio = (bio) => {
    const words = bio.split(' ');
    if (words.length > 8) {
      return words.slice(0, 8).join(' ') + '...';
    }
    return bio;
  };
  
  const getLocationString = (city, state) => {
    if (city && state) return `(${city}, ${state})`;
    if (city) return `(${city})`;
    if (state) return `(${state})`;
    return '';
  };
  
  const handleUserNameClick = (userName) => {
    const updatedClickedUserNames = { ...clickedUserNames, [userName]: true };
    setClickedUserNames(updatedClickedUserNames);
    localStorage.setItem('clickedUserNames', JSON.stringify(updatedClickedUserNames));
    navigate(`/clipsusersposts/${userName}`);
  };
  
  const randomUserBios = useMemo(() => {
    const shuffledBios = Object.entries(userBios);
    for (let i = shuffledBios.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledBios[i], shuffledBios[j]] = [shuffledBios[j], shuffledBios[i]];
    }
    return shuffledBios.slice(0, Math.min(8, shuffledBios.length));
  }, [userBios, randomBiosKey]);
  
  return (
    <Box sx={{ pb: 4 }}>
      <div className="posts">
        {discoverPosts.length > 0 && (
          <Fade in={true} timeout={800} unmountOnExit>
            <Paper
              elevation={3}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                p: 4,
                mt: 4,
                width: '100%',
                maxWidth: '100%',
                mb: 2,
                borderRadius: '16px',
                backgroundColor: '#fff',
                overflow: 'hidden',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', mb: 4 }}>
                <Typography variant="h4" sx={{ fontWeight: '700', color: '#1d1d1f' }}>
                  Discover Posts
                </Typography>
                <IconButton 
                  onClick={handleRefresh}
                  sx={{ 
                    color: '#007aff',
                    backgroundColor: '#f5f5f7',
                    '&:hover': { backgroundColor: '#e8e8ed' },
                    transition: 'background-color 0.3s ease',
                  }}
                >
                  <RefreshIcon />
                </IconButton>
              </Box>
  
              <List sx={{ width: '100%', padding: 0 }}>
                {randomDiscoverPosts.map((post, index) => (
                  <VisibilityTracker key={post.id} postId={post.id} isDiscoveryBox={true}>
                    <Fade in={true} timeout={500 + index * 100} unmountOnExit>
                      <ListItem 
                        component="div"
                        sx={{ 
                          display: 'flex', 
                          flexDirection: 'column', 
                          alignItems: 'flex-start',
                          padding: '16px 0',
                          borderRadius: '8px',
                          '&:hover': { backgroundColor: 'transparent' },
                        }}
                      >
                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mb: 1 }}>
                          <Typography
                            variant="body1"
                            component="span"
                            onClick={() => handlePostClick(post.id)}
                            sx={{
                              color: clickedPosts[post.id] ? '#551A8B' : '#1a0dab',
                              textDecoration: 'none',
                              cursor: 'pointer',
                              fontWeight: 'bold',
                              '&:hover': { textDecoration: 'underline' },
                              fontSize: '1.1rem',
                              marginBottom: '4px',
                            }}
                          >
                            {post.title}
                          </Typography>
                          <Typography variant="body2" component="span" sx={{ color: '#86868b', fontSize: '0.9rem' }}>
                            {post.city && post.state ? `(${post.city}, ${post.state})` : 
                              post.city ? `(${post.city})` : 
                              post.state ? `(${post.state})` : ''}
                          </Typography>
                        </Box>
                        <Typography variant="body2" sx={{ color: '#1d1d1f', fontWeight: 400, fontSize: '0.95rem' }}>
                          {post.summary || (post.description.length > 100 ? `${post.description.substring(0, 100)}...` : post.description)}
                        </Typography>
                      </ListItem>
                    </Fade>
                  </VisibilityTracker>
                ))}
              </List>
  
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', mt: 4 }}>
                <Typography variant="body1" sx={{ color: '#333', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                  <span style={{ backgroundColor: '#fff', color: '#000', fontFamily: 'Bebas Neue, sans-serif', fontSize: '1.5rem', padding: '5px 10px', border: '2px solid #000', borderRadius: '5px', marginRight: '10px' }}>
                    {totalImpressions.toLocaleString()}
                  </span>
                  Total Post Impressions
                </Typography>
              </Box>
            </Paper>
          </Fade>
        )}
  
        {uniquePosts.filter(post => post.showOnHomePage).map(post => (
          <div key={post.id} className="post-container">
            <ClipsPost {...post} />
          </div>
        ))}
  
        <Fade in={true} timeout={800} unmountOnExit>
          <Paper
            elevation={3}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              p: 4,
              mt: 4,
              mb: 4,
              width: '100%',
              maxWidth: '800px',
              margin: '0 auto 2rem auto',
              borderRadius: '16px',
              backgroundColor: '#fff',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                mb: 4,
              }}
            >
              <Typography variant="h4" sx={{ fontWeight: '700', color: '#1d1d1f' }}>
                Discover People
              </Typography>
              <IconButton 
                onClick={handleBioRefresh}
                sx={{ 
                  color: '#007aff',
                  backgroundColor: '#f5f5f7',
                  '&:hover': { backgroundColor: '#e8e8ed' },
                  transition: 'background-color 0.3s ease',
                }}
              >
                <RefreshIcon />
              </IconButton>
            </Box>
  
            {Object.keys(userBios).length === 0 ? (
              <Typography variant="body1" sx={{ color: '#86868b' }}>
                No user profiles available.
              </Typography>
            ) : (
              <List sx={{ width: '100%', padding: 0 }}>
                {randomUserBios.map(([author, { bio, city, state, name }], index) => (
  <Fade in={true} timeout={500 + index * 100} key={`${author}-${index}`} unmountOnExit>
    <ListItem
      component="div"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '16px 0',
        borderRadius: '8px',
        '&:hover': { backgroundColor: 'transparent' },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mb: 1 }}>
        <Typography
          variant="body1"
          component="span"
          onClick={() => handleUserNameClick(author)}
          sx={{
            color: clickedUserNames[author] ? '#551A8B' : '#1a0dab',
            textDecoration: 'none',
            cursor: 'pointer',
            fontWeight: 'bold',
            '&:hover': { textDecoration: 'underline' },
            fontSize: '1.1rem',
            marginBottom: '4px',
          }}
        >
          {name || author}
        </Typography>
        <Typography variant="body2" component="span" sx={{ color: '#86868b', fontSize: '0.9rem' }}>
          {getLocationString(city, state)}
        </Typography>
      </Box>
      <Typography
        variant="body2"
        sx={{ color: '#1d1d1f', fontWeight: 400, fontSize: '0.95rem' }}
      >
        {truncateBio(bio)}
      </Typography>
    </ListItem>
  </Fade>
))}
              </List>
            )}
          </Paper>
        </Fade>
      </div>
    </Box>
  );
};

export default HomePage;